import React, { useEffect, useState } from "react";

import { auth } from "../../../utils/auth";
import { toast, formatNumber, formatCurrency } from "../../../utils/helpers";
import dayjs from "dayjs";
import Loader from "../../general/Loader";
import { isNull } from "lodash";
import { request } from "../../../utils/request";

const ReporteOperacionesInventario = () => {
    const [productosSeleccionados, setProductosSeleccionados] = useState([]);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        getReporte();
    }, [])

    const getReporte = async () => {
        const response = await request(`reportes${window.location.search}`);

        if (response.success) {
            setProductosSeleccionados(response.data);
            setSuccess(true);
            //window.print();
        } else {
            toast("Hubo un error y no se pueden cargar los datos.", "error");
        }
    };

    if (productosSeleccionados.length === 0 && success === false) {
        return <Loader />;
    }

    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        suc => suc.id === user.sucursalActual
    )[0];

    const productos = productosSeleccionados.reduce((acc, current) => {
        acc[current.nombre] = {
            nombre: current.nombre,
            disponible: current.cantidad,
            referencia: current.referencia,
            unidad: current.unidad,
            grupo: current.grupo?.nombre,
            almacen: current.almacen?.nombre,
            totalEntradas: 0,
            totalSalidas: 0,
            cantidadEntradas: 0,
            cantidadSalidas: 0,
            operacionesRaw: [
                ...current.ajustes.map(a => {a.tipo = 'ajuste'; return a;}),
                ...current.facturas.map(a => {a.tipo = 'factura'; return a;}),
                ...current.compras.map(a => {a.tipo = 'compra'; return a;}),
            ]
        };
        return acc;
    }, {});

    Object.keys(productos).forEach(producto => {
        productos[producto].operaciones = productos[producto].operacionesRaw.map(operacion => {
            let tipoOperacion = (operacion.incrementar === 1 || operacion.compra) ? "ENTRADA" : "SALIDA";
            const estado = operacion.factura?.estado || operacion.compra?.estado || "completada";
            if (estado === 'cancelada') {
                tipoOperacion = 'CANCELADA';
            }
            const entrada = tipoOperacion === 'ENTRADA';
            const salida = tipoOperacion === 'SALIDA';
            const factura = operacion.tipo === 'factura';
            const compra = operacion.tipo === 'compra';
            const ajuste = operacion.tipo === 'ajuste';

            return {
                id: operacion.id,
                fecha: dayjs(operacion.compra?.fecha || operacion.factura?.fecha || operacion.created_at).format("YYYY-MM-DD"),
                operacion: tipoOperacion,
                estado: estado,
                entradas: entrada ? operacion.cantidad : "-",
                salidas: salida ? operacion.cantidad || operacion.detalle.cantidad : "-",
                monto: factura ? operacion.precio_facturado :
                    ajuste ? "-" :
                    compra ? operacion.costo + operacion.impuesto : "-",
                total: factura ? operacion.total :
                    ajuste ? "-" :
                    operacion.total != null ? operacion.total :
                    operacion?.costo != null && isNull(operacion.nota) ? operacion.cantidad * operacion.costo :
                    operacion.nota != null ? operacion.nota.total : "-",
                disponible: (factura && operacion.detalle?.stock_disponible) ? operacion.detalle?.stock_disponible :
                    ajuste ? operacion.existencia :
                    compra ? operacion.stock_disponible : 0,
                observacion: factura ? "Venta " + operacion.factura?.numero_comprobante :
                    (ajuste && (operacion.nota != null || operacion.crear)) ? operacion.descripcion :
                    (ajuste && !operacion.nota) ? "Ajuste Manual - " + operacion.descripcion :
                    compra ? "Compra  " + operacion.compra.ncf + (operacion.compra.numeracion || '') : '',
                usuario: entrada && operacion.compra?.nota_credito ? operacion.compra.nota_credito.usuario?.nombre.toUpperCase() + " " + operacion.compra.nota_credito.usuario?.apellido.toUpperCase() :
                    factura ? (operacion?.factura?.vendedor && operacion?.factura?.vendedor?.nombre + " " + operacion?.factura?.vendedor?.apellido) :
                    compra ? (operacion.compra?.usuario && operacion.compra?.usuario?.nombre.toUpperCase() + " " + operacion.compra?.usuario?.apellido.toUpperCase()) :
                    ajuste ? (operacion.usuario && operacion.usuario?.nombre.toUpperCase() + " " + operacion.usuario?.apellido.toUpperCase()) : "-",
            }
        });

        productos[producto].operaciones.sort((a,b) => {
            return dayjs(b.fecha) - dayjs(a.fecha);
        });

        let disponible = productos[producto].disponible;
        productos[producto].operaciones.map((operacion, index) => {
            operacion.disponible = disponible;
            if (operacion.operacion === 'ENTRADA') {
                disponible -= parseFloat(operacion.entradas);
            } else if (operacion.operacion === 'SALIDA') {
                disponible += parseFloat(operacion.salidas);
            }
            return operacion;
        });

        productos[producto].operaciones.sort((a,b) => {
            return dayjs(a.fecha) - dayjs(b.fecha) || b.disponible - a.disponible;
        });

        productos[producto].totalEntradas = productos[producto].operaciones.reduce((acc, operacion) => {
            return operacion.operacion === 'ENTRADA' && operacion.total !== '-' ? acc + parseFloat(operacion.total) : acc;
        }, 0);

        productos[producto].totalSalidas = productos[producto].operaciones.reduce((acc, operacion) => {
            return operacion.operacion === 'SALIDA' && operacion.total !== '-' ? acc + parseFloat(operacion.total) : acc;
        }, 0);

        productos[producto].cantidadEntradas = productos[producto].operaciones.reduce((acc, operacion) => {
            return operacion.entradas !== '-' ? acc + parseFloat(operacion.entradas) : acc;
        }, 0);
        productos[producto].cantidadSalidas = productos[producto].operaciones.reduce((acc, operacion) => {
            return operacion.salidas !== '-' ? acc + parseFloat(operacion.salidas) : acc;
        }, 0);
    });

    return (
        <div>
            <style>{`@page {size: 11in 8.5in;}`}</style>
            <div className='row'>
                <h2 className='col-md-12 text-center m-b-5'>
                    Reporte Operaciones de Inventario
                </h2>
                <div className='col-md-12 text-center'>
                    {sucursal.nombre} - {sucursal.direccion} •{" "}
                    {new Date().toLocaleString()}
                </div>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                    </div>
                </div>
            </div>

            {Object.keys(productos).map(producto => {
                return (
                    <div className="seccion-producto m-b-80">
                        <table className='table print-productos m-b-10'>
                            <thead className="head-table-producto">
                                <tr>
                                    <th colSpan="12">
                                        {productos[producto].referencia} - {producto}<br />
                                        <small>Disponible: {productos[producto].disponible} | Almacen: {productos[producto].almacen} | Categoria: {productos[producto].grupo} </small>
                                    </th>
                                </tr>
                                <tr>
                                    <th width="100px">Fecha</th>
                                    <th>Operación</th>
                                    <th>Entradas</th>
                                    <th>Salidas</th>
                                    <th>Monto</th>
                                    <th>Total</th>
                                    <th>Disponible</th>
                                    <th>Observación</th>
                                    <th>Realizado por</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productos[producto].operaciones.map((operacion) => {
                                    return (
                                        <tr key={operacion.id}>
                                            <td>{operacion.fecha}</td>
                                            <td>{operacion.operacion}</td>
                                            <td>{operacion.entradas === '-' ? operacion.entradas :  formatNumber(operacion.entradas)}</td>
                                            <td>{operacion.salidas === '-' ? operacion.salidas : formatNumber(operacion.salidas)}</td>
                                            <td>{operacion.monto === '-' ? operacion.monto : formatNumber(operacion.monto)}</td>
                                            <td>{operacion.total === '-' ? operacion.total : formatNumber(operacion.total)}</td>
                                            <td>{formatNumber(operacion.disponible)}</td>
                                            <td>{operacion.observacion}</td>
                                            <td>{operacion.usuario}</td>
                                        </tr>
                                    );
                                })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <b>
                                                {productos[producto].cantidadEntradas}
                                            </b>
                                        </td>
                                        <td>
                                            <b>
                                                {productos[producto].cantidadSalidas}
                                            </b>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                            </tbody>
                        </table>
                        <span className="float-left">
                            <b>Monto total de entradas: </b>{formatCurrency(productos[producto].totalEntradas)}<br/>
                            <b>Monto total de salidas: </b>{formatCurrency(productos[producto].totalSalidas)}<br/>
                        </span>

                    </div>
                )}
            )}

            <div className='row'>
                <div className='col-md-12 text-right p-b-10'>
                    <div className='float-left'>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReporteOperacionesInventario;
