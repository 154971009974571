/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { request } from "../../../utils/request";
import { formatCurrency, getSucursal } from "../../../utils/helpers";

const PrintSingleRecibo = () => {
	const { id } = useParams();
	// const location = useLocation();

	const [reciboIngreso, setReciboIngreso] = useState({
		cuentas: [],
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);
	const [status, setStatus] = useState("Original");

	const getRecibo = () => {
		request("recibos-ingreso/" + id).then((res) => {
			setReciboIngreso(res.data);
			setTimeout(() => {
				window.print();
			}, 500);
		});
	};

	const onChangeStatusRecibo = (estado) => {
		setStatus(estado);
	};

	useEffect(() => {
		getRecibo();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);
	useEffect(() => {
		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined
		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;
		setInfoBlock2Margin((count * 25 + 10) * -1);
	}, [reciboIngreso]);
	return (
		<>
			<ReciboIngresoTemplate
				recibo={reciboIngreso}
				sucursal={sucursal}
				infoBlock2Margin={infoBlock2Margin}
				sumatoriaFinal={reciboIngreso.abono_total}
				status={status}
			/>
			<div className="ordernar-resultados no-print">
				<div className="form-group">
					<div className="radio-btn">
						<label>
							<input
								type="radio"
								onChange={() =>
									onChangeStatusRecibo("Original")
								}
								name="orden"
								value="Original"
								defaultChecked={true}
							/>{" "}
							Original
						</label>
						<label>
							<input
								type="radio"
								onChange={() => onChangeStatusRecibo("Copia")}
								name="orden"
								value="Copia"
							/>{" "}
							Copia
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export const ReciboIngresoTemplate = ({
	recibo,
	sucursal,
	infoBlock2Margin,
	status = "",
}) => {
    const retencion = recibo.cuentas?.reduce((acc, cuenta) => {
        return acc + (cuenta.factura?.retencion ?? 0);
    }, 0);
	return (
		<>
		<style>
			{`
			@media (max-width: 575px){
				
				.sucursal-name-sm{
					display:block;
				}
				.recibo-logo{
					display:none;
				}
				.sucursal-name-lg{
					display:none;
				}
				.sm-invoice-table > div.row {
					font-size: 15.5px !important;
				   }
			
				.summary{
					padding-top: 10px !important;
				}
				.cliente-direccion, .sucursal-name-sm{
					line-height: 20px !important;				
					margin-bottom: 3px;
				}
				.summary >div.row{
					justify-content: flex-end !important;
					margin-bottom:5px !important;
					line-height:16px !important;
					
				}
				.justify-content-between :nth-child(2){
					text-align:left !important;
					max-width: 33% !important;
				}
				.summary :nth-child(1), .summary :nth-child(2){
					padding-left: 5px !important;
					padding-right: 2px !important;
					font-size:15.5px !important;
					// text-transform: capitalize;
					font-weight:500 !important;
				}
				.summary >div.row :nth-child(2), .summary >div>div.row :nth-child(2) {
					max-width: 25% !important;
					width: auto !important;
					flex: 0 0 25% !important;
					text-align: left !important;
				
					
				}
				
			}
			`}
		</style>
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-sm-left sucursal-info">
					<h3 className="sucursal-name-sm">
						{sucursal.nombre}
					</h3>
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 recibo-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : recibo.logo ? (
						<img
							alt="logo"
							className="mb-2 recibo-logo"
							src={recibo.logo[0].url}
							width="270px"
						/>
					) : (
						<h3 className="sucursal-name-lg"
							style={{
								lineHeight: "16px",
								marginBottom: "3px",
							}}>
								{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-sm-0"
						style={{lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-sm-right text-sm-right">
					<div
						className="mb-2 invoice-fecha d-block d-sm-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(recibo.created_at).format("DD/MM/YYYY")}
					</div>
					<h3
						className="d-block d-sm-none"
						style={{
							fontSize: "15.5px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
					>
						Recibo de {recibo.tipo_recibo === "ingreso" ? "Ingreso" : "Egreso"}
					</h3>
					<div
						className="d-block d-sm-none"
						style={{ fontSize: "medium", lineHeight: "16px" }}
					>
						<span>NO.: </span>
						{recibo.numeracion}
						<br />
						{recibo.numeracion?.fecha_vencimiento && (
							<>
								<span>FV:</span>{" "}
								{dayjs(
									recibo.numeracion?.fecha_vencimiento
								).format("DD/MM/YYYY")}
							</>
						)}
					</div>

					<span className="d-block d-sm-none client-info-title pt-2">
						{status}
					</span>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-sm-none">
				{recibo.cliente && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">{recibo.tipo_recibo === "ingreso" ? "Cliente:" : "Suplidor:"}</span>
						</div>
						<div className="col-7" style={{ lineHeight: "16px" }}>
							{recibo.cliente?.nombre}
						</div>
					</div>
				)}
				{recibo.cliente?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{recibo.cliente?.identificacion}
						</div>
					</div>
				)}
				{recibo.cliente?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{recibo.cliente?.telefono}
						</div>
					</div>
				)}
				{recibo.cliente?.direccion && (
					<div className="row cliente-direccion">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7">
							{recibo.cliente?.direccion}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{recibo.cliente?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										{recibo.tipo_recibo === "ingreso" ? "Cliente:" : "Suplidor:"}
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{recibo.cliente?.nombre}
									</td>
								</tr>
							)}
							{recibo.cliente?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{recibo.cliente?.identificacion}
									</td>
								</tr>
							)}
							{recibo.cliente?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{recibo.cliente?.telefono}
									</td>
								</tr>
							)}
							{recibo.cliente?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{recibo.cliente?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td style={{ paddingLeft: "10px" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											textAlign: "right",
										}}
									>
										{status}
									</h3>
								</td>
							</tr>
							<tr>
								<td colSpan={1}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
											whiteSpace: "nowrap",
											paddingLeft: "50%",
											textAlign: "right",
										}}
									>
										Recibo de {recibo.tipo_recibo === "ingreso" ? "Ingreso" : "Egreso"}
									</h3>
								</td>
							</tr>
							{recibo.created_at && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(recibo.created_at).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{recibo.numeracion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										No.:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{recibo.numeracion}
									</td>
								</tr>
							)}
							{recibo.tipo_pago && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										MÉTODO DE PAGO:
									</td>
									<td style={{ paddingLeft: "10px", textTransform: "capitalize" }}>
										{recibo.tipo_pago}
									</td>
								</tr>
							)}
							{recibo.tipo_pago === "cheque" && recibo.detalle_metodo_pago?.num_cheque && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										NÚMERO CHEQUE:
									</td>
									<td style={{ paddingLeft: "10px", textTransform: "uppercase" }}>
										{recibo.detalle_metodo_pago.num_cheque}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper">
				<h3 className="table-title"></h3>
				<table className="table d-none d-sm-table">
					<thead>
						<tr className="text-uppercase">
							<th width="170px">NCF/Número</th>
							<th width="240px">Fecha de vencimiento</th>
							<th>Deuda</th>
							<th>Abono</th>
							<th>Restan</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{recibo.tipo_recibo === "ingreso" ? recibo?.cuentas?.map((cuenta) => {
							return (
								<tr key={cuenta.id}>
									<td>
										{cuenta.factura?.numero_comprobante}
									</td>
									<td>
										{dayjs(
											cuenta.factura?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
									<td>{formatCurrency(cuenta.deuda)}</td>
									<td>{formatCurrency(cuenta.abono)}</td>
									<td>{formatCurrency(cuenta.balance)}</td>
									<td>{formatCurrency(cuenta.abono)}</td>
								</tr>
							);
						}) : recibo?.compras?.map((compra) => {
							return (
								<tr key={compra.id}>
									<td>
										{compra.compra?.ncf}
									</td>
									<td>
										{dayjs(
											compra.compra?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
									<td>{formatCurrency(compra.deuda)}</td>
									<td>{formatCurrency(compra.abono)}</td>
									<td>{formatCurrency(compra.balance)}</td>
									<td>{formatCurrency(compra.abono)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{/* PRODUCTOS RESPONSIVE */}
			<div className="sm-invoice-table">
				<div className="row" style={{ fontWeight: "600" }}>
					<div className="col-3">NCF/NO</div>
					<div className="col-3 px-0">DEUDA</div>
					<div className="col-3 px-0">ABONO</div>
					<div className="col-3">RESTAN</div>
				</div>
				

				{recibo.cuentas?.map((cuenta) => {
					return (
						<>
							<div
								className="row"
								style={{
									fontSize: "smaller",
									marginBottom: "5px",
								}}
							>
								<div
									className="col-3"
									style={{ lineHeight: "13px" }}
								>
									{cuenta.factura?.numero_comprobante}
								</div>
								<div className="col-3 px-0">
									{formatCurrency(cuenta.deuda)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(cuenta.abono)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(cuenta.balance)}
								</div>
							</div>
							
						</>
					);
				})}
			</div>
			
			<div className="summary mt-sm-3">
				{/* Balance */}
				{retencion > 0 && (
                    <div className="row justify-content-between justify-content-md-end m-b-10">
                        <div
                            className="col col-md-3 text-sm-right uppercase"
                            style={{
                                maxWidth: "10rem",
                                lineHeight: "16px",
                                fontWeight: "600",
                            }}
                        >
                            Balance:
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{
                                maxWidth: "8rem",
                                lineHeight: "16px",
                                fontWeight: "600",
                            }}
                        >
                            {formatCurrency(recibo.abono_total)}
                        </div>
                    </div>
                )}

				{/* Retención */}
                {retencion > 0 && (
                    <div className="row justify-content-between justify-content-md-end m-b-10">
                        <div
                            className="col col-md-3 text-sm-right uppercase"
                            style={{
                                maxWidth: "10rem",
                                lineHeight: "16px",
                                fontWeight: "600",
                            }}
                        >
                            Retención:
                        </div>
                        <div
                            className="col-2 text-right"
                            style={{
                                maxWidth: "8rem",
                                lineHeight: "16px",
                                fontWeight: "600",
                            }}
                        >
                            {formatCurrency(retencion)}
                        </div>
                    </div>
                )}

				{/* Total Pagado */}
				<div className="row justify-content-between justify-content-md-end m-b-10">
					<div
						className="col col-md-3 text-sm-right"
						style={{
							maxWidth: "10rem",
							lineHeight: "14px",
							fontWeight: "600",
						}}
					>
						TOTAL PAGADO:
					</div>
					<div
						className="col-2 text-right"
						style={{
							maxWidth: "8rem",
							lineHeight: "14px",
							fontWeight: "600",
						}}
					>
						{formatCurrency(recibo.abono_total - retencion)}
					</div>
				</div>
			</div>

			<hr className="d-block d-sm-none mt-0 mb-4" />

			{recibo.notas && (
				<>
					<p style={{ fontWeight: "600", fontSize: "1rem" }}>
						Notas:
					</p>
					<p
						className="col-12 col-md-8 pl-0 mb-3"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{recibo.notas}
					</p>
				</>
			)}

			<div className="row justify-content-center mt-5">
				<h3
					className="col-10 col-sm-3 text-center mr-sm-2 mr-0 mt-3 mt-sm-4 fw-600 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Elaborado por
				</h3>
				<div className="col-sm-2 d-none d-sm-block"></div>
				<h3
					className="col-10 col-sm-3 text-center ml-sm-2 ml-0 fw-600 mt-5 mt-sm-4 signature-title"
					style={{ borderTop: "1px solid #000" }}
				>
					Recibido por
				</h3>
			</div>

			{recibo.plantilla?.pie_pagina && recibo.tipo_recibo === "ingreso" && (
				<div
					className="col-12 pt-5 text-center d-flex justify-content-center"
					style={{
						fontWeight: "600",
						fontSize: "1rem",
						whiteSpace: "pre-line",
						lineHeight: "normal",
					}}
				>
					<p className="text-center pie-pagina">
						{recibo.plantilla?.pie_pagina}
					</p>
				</div>
			)}
		</div>
		</>
	);
};

export default PrintSingleRecibo;
