import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { withRequest } from "../../utils/request";
import Modal from "../general/Modal";
import dayjs from "dayjs";

class EditCuota extends Component {
    state = {
        fecha: '',
        monto: '',
        cuotasPendientes: '',
        errors: {},
        cuota: {},
        showDelete: false,
    };
    editDescuento = async (event) => {
        event.preventDefault();

        const token = auth.getToken();
        const data = new FormData(event.target);

        const res = await fetch(
            apiUrl(`descuentos/cuota/${this.props.descuentoId}`),
            {
                method: "post",
                body: data,
                headers: {
                    Accept: "application/json",
                    "X-Request-With": "XMLHttpRequest",
                    Authorization: "Bearer " + token,
                },
            }
        );

        const response = await res.json();

        if (response.success) {
            this.props.getDescuentos();
            this.props.toggle();
        }

        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    deleteDescuento = async (descuento) => {
        const token = auth.getToken();

        const url = `descuentos/cuota/${descuento}`;

        const res = await fetch(apiUrl(url), {
            method: "delete",
            headers: {
                Accept: "application/json",
                "X-Request-With": "XMLHttpRequest",
                Authorization: "Bearer " + token,
            },
        });

        const response = await res.json();

        if (response.success) {
            this.props.getDescuentos();
            this.props.toggle();
            toast("Descuento borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };
    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };
    render() {
        const { errors } = this.state;
        const { toggle, descuento, descuentoId } = this.props;

        const cuota = descuento.cuotas?.length ? descuento.cuotas.filter(c => c.id === descuentoId)[0] : {};

        /* const proxCuota = descuento.cuotasPendientes.length && dayjs(
            descuento.cuotasPendientes[0].fecha
        ).format(
            "YYYY-MM-DD"
        );

        const canEdit = dayjs(cuota.fecha).format('YYYY-MM-DD') === proxCuota; */

        return (
            <div>
                <form method='post' onSubmit={this.editDescuento}>
                    <input type='hidden' name='_method' value='patch' />
                    <div className='popup__header'>Editar Cuota</div>
                    <div className="popup__body">
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Monto:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='number'
                                    name='monto'
                                    step="0.01"
                                    defaultValue={cuota.monto}
                                    className='form-control'
                                />
                                {errors.monto && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.monto[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Fecha:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <input
                                    type='date'
                                    name='fecha'
                                    defaultValue={dayjs(cuota.fecha).format('YYYY-MM-DD')}
                                    className='form-control'
                                />
                                {errors.fecha && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.fecha[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Estado:
                                </label>
                            </div>
                            <div className='col col-md-8'>
                                <select
                                    name='cuotas_pendientes'
                                    defaultValue={cuota.estado === 'Pendiente' ? 1 : 0}
                                    className='form-control'
                                >
                                    <option value="1">Pendiente</option>
                                    <option value="0">Pagada</option>
                                </select>
                                {errors.cuotas_pendientes && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.cuotas_pendientes[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className='col col-md-4'>
                                <label className='label form-control-label'>
                                    Comentario:
                                </label>
                            </div>
                            <div className="col">
                                <textarea required name="comentario" rows="4" width="100%" className="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='popup__footer'>
                        {/* <button
                            type='button'
                            className='btn float-left'
                            onClick={this.toggleDeleteModal}>
                            <i className='fas fa-trash-alt'></i>
                        </button> */}
                        <button
                            type='button'
                            onClick={toggle}
                            className='btn btn-plain'>
                            Cancelar
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    </div>
                </form>
                <Modal
                    title='Borrar Cuota'
                    show={this.state.showDelete}
                    callback={() => this.deleteDescuento(descuentoId)}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar esta cuota?
                </Modal>
            </div>
        );
    }
}

export default withRequest(EditCuota);
