import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { formatCurrency, formatNumber } from "../../../utils/helpers";
import { auth } from "../../../utils/auth";

class DetalleComisiones extends Component {
    state = {
        orden: 'nombre',
        nomina: {},
        redondear: false,
    };
    componentDidMount() {
        this.getNomina();
    }
    getNomina = async (url = null) => {
        const {id, comision} = this.props.match.params;
        const search = window.location.search ? window.location.search : "";
        url = url ? url : `nominas/${id}/comisiones/${comision}${search}`;
        const nomina = await this.props.request(url);

        if (nomina.success) {
            this.setState({
                nomina: nomina.data,
            });
            //window.print();
        }
    };
    onChangeOrden = (e) => {
        this.setState({
            orden: e.target.value
        });
    }
    onChangeRedondear = () => {
        this.setState({
            redondear: !this.state.redondear
        });
    }
    redondear = (cantidad) => {
        return Math.round(cantidad/5) * 5;
    }
    render() {
        const { nomina } = this.state;

        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];
        const query = queryString.parse(window.location.search);

        const totales = nomina?.transacciones?.reduce((a,t) => {
            a.subtotal += t.subtotal;
            a.monto += t.monto;
            a.comision += t.comision;
            return a;
        }, {subtotal: 0, monto: 0, comision: 0});

        const numeraciones = nomina?.transacciones?.reduce((acc, pago) => {
            if (typeof acc[pago.numeracion] === "undefined") {
                acc[pago.numeracion] = [];
            }
            acc[pago.numeracion].push(pago);

            return acc;
        }, {}) || [];

        return (
            <div>
                <style>{`@page {size: 11in  8.5in;} *{font-size: 14px}`}</style>
                <div className='row'>
                    {nomina.cerradaEn && (
                        <div className='fecha_cierre col-md-12'>
                            Nomina Cerrada:{" "}
                            {dayjs(nomina.cerradaEn).format("DD/MM/YYYY")}
                        </div>
                    )}
                    <h3 className='col-md-12 text-center m-b-5'>
                        Detalle de Comisiones por {nomina.tipo} {" "} - {nomina.codigo} - {nomina.empleado}
                        {query.contrato === "informal" ? "Informales" : ""}
                    </h3>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {dayjs(nomina.desde).format("DD/MM/YYYY")} -{" "}
                        {dayjs(nomina.hasta).format("DD/MM/YYYY")}
                    </div>
                </div>
                <div className='row text-right'>
                    <div className='col text-left'>
                        TRANSACCIONES: {nomina.transacciones?.length} - PORCENTAJE COMISIÓN: {nomina.porcentaje}%
                    </div>

                    <div className='col text-right'>
                        <h3>TOTAL: {formatCurrency(nomina.total)}</h3>
                    </div>
                </div>
                <div>
                    {Object.keys(numeraciones).map(numeracion => {
                        const totalSubtotal = numeraciones[numeracion].reduce((acc, pago) => {
                            return acc + pago.subtotal;
                        }, 0);
                        const totalImpuestos = numeraciones[numeracion].reduce((acc, pago) => {
                            return acc + (pago.monto - pago.subtotal);
                        }, 0);
                        const totalGeneral = numeraciones[numeracion].reduce((acc, pago) => {
                            return acc + pago.monto;
                        }, 0);
                        const totalComision = numeraciones[numeracion].reduce((acc, pago) => {
                            return acc + pago.comision;
                        }, 0);
                        const totalNotasCredito = numeraciones[numeracion].reduce((acc, pago) => {
                            return acc + parseFloat(pago.notas_credito);
                        }, 0);

                        return (<>
                            <h3>{numeracion} <span className="float-right">{numeraciones[numeracion].length} Transacciones</span></h3>
                            <table className='table m-b-50'>
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>NFC/Número</th>
                                        <th>Fecha</th>
                                        <th>Fecha Saldada</th>
                                        <th className="text-right">Subtotal</th>
                                        <th className="text-right">Impuesto</th>
                                        <th className="text-right">Total</th>
                                        <th className='text-right'>Notas Credito</th>
                                        <th className='text-right'>Comisión</th>
                                        {/* <th className='text-right'>nota</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {numeraciones[numeracion].map((transaccion, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{transaccion.cliente}</td>
                                                <td>{transaccion.numero}</td>
                                                <td>{transaccion.fecha}</td>
                                                <td>{transaccion.fecha_pago}</td>
                                                <td className="text-right">{formatCurrency(transaccion.subtotal)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.monto - transaccion.subtotal)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.monto)}</td>
                                                <td className="text-right">{transaccion.notas_credito > 0 && formatCurrency(transaccion.notas_credito)}</td>
                                                <td className="text-right">{formatCurrency(transaccion.comision)}</td>
                                                {/* <td className="text-right">{transaccion.nota}</td> */}
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right bold">{formatCurrency(totalSubtotal)}</td>
                                        <td className="text-right bold">{formatCurrency(totalImpuestos)}</td>
                                        <td className="text-right bold">{formatCurrency(totalGeneral)}</td>
                                        <td className="text-right bold">{formatCurrency(totalNotasCredito)}</td>
                                        <td className="text-right bold">{formatCurrency(totalComision)}</td>
                                        {/* <td></td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </>)
                    })}
                </div>

                <h3 className="text-center">Totales</h3>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className="text-center">Transacciones</th>
                            <th className="text-center">Porcentaje Comisión</th>
                            <th className="text-center">Importe</th>
                            <th className="text-center">Impuesto</th>
                            <th className="text-center">Ventas Total</th>
                            <th className='text-center'>Comisión Total</th>
                            {/* <th className='text-right'>nota</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">{nomina.transacciones?.length}</td>
                            <td className="text-center">{nomina.porcentaje}</td>
                            <td className="text-center">{formatCurrency(totales?.subtotal)}</td>
                            <td className="text-center">{formatCurrency(totales?.monto - totales?.subtotal)}</td>
                            <td className="text-center">{formatCurrency(totales?.monto)}</td>
                            <td className="text-center">{formatCurrency(totales?.comision)}</td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(withRequest(DetalleComisiones));
