/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../../utils/request";
import { formatCurrency, getSucursal, money } from "../../../../utils/helpers";
import dayjs from "dayjs";

const tipos_impuesto = {
	itbis: "Itbis",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const getDiasPlazo = (fecha, fecha_vencimiento) => {
	fecha = new Date(fecha);
	fecha_vencimiento = new Date(fecha_vencimiento);

	let difference = fecha.getTime() - fecha_vencimiento.getTime();
	let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
	return Math.abs(TotalDays);
};

const limitVendedorFullName = (name) => {
	name = name.split(" ");

	if (name.length <= 3) return name.join(" ");

	return name.slice(0, 3).join(" ");
};

const tipoITBIS = (impuesto) => {
	if (!impuesto) return "";
	if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.16) {
		return "I1";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0.18) {
		return "I2";
	} else if (impuesto.tipo === "itbis" && impuesto.porcentaje === 0) {
		return "IE";
	}
};

const PrintSingleOrden = () => {
	const { id } = useParams();
	const [factura, setFactura] = useState({
		productos: [],
		cliente: {},
		vendedor: {},
	});
	const [sucursal, setSucursal] = useState({});
	const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		impuestos: {},
	});
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const getFactura = () => {
		request("ordenes-compra/" + id).then((res) => {
			setFactura(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		});
	};

	const calcularImpuestos = (productos) => {
		let impuestos = {};

		if (productos) {
			productos.forEach((producto) => {
				if (
					!tipos_impuesto[producto.impuesto?.tipo] ||
					producto.impuesto?.porcentaje === 0
				)
					return;
				if (
					isNaN(
						impuestos[
							`${tipos_impuesto[producto.impuesto.tipo]} ${
								producto.impuesto.porcentaje * 100
							}%`
						]
					)
				) {
					impuestos[
						`${tipos_impuesto[producto.impuesto.tipo]} ${
							producto.impuesto.porcentaje * 100
						}%`
					] = 0;
				}
				impuestos[
					`${tipos_impuesto[producto.impuesto.tipo]} ${
						producto.impuesto.porcentaje * 100
					}%`
				] +=
					producto.cantidad * (
					producto.precio_facturado -
					producto.importe);
			});
		}

		return impuestos;
	};

	useEffect(() => {
		getFactura();
		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);

		// identify if mobile
		let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i),
			isTablet = navigator.userAgent.toLowerCase().match(/tablet/i),
			isAndroid = navigator.userAgent.toLowerCase().match(/android/i),
			isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i),
			isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);

		if (isMobile || isTablet || isAndroid || isiPhone || isiPad) {
			setIsMobileDevice(true);
		}
	}, []);
	useEffect(() => {
		setSumatoriaFinal((state) => ({
			...state,
			impuestos: calcularImpuestos(factura.productos),
		}));

		// This is for calculate the margin of the second column of client info. that way the last row of each column will be alined\
		if (isMobileDevice) return;

		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;

		if (count >= 5 && !factura.logo && !sucursal.logo) {
			setInfoBlock2Margin((count * 25 - 40) * -1);
		} else {
			setInfoBlock2Margin((count * 25 + 10) * -1);
		}
	}, [factura]);
	return (
		<OrdenTemplate
			factura={factura}
			sucursal={sucursal}
			infoBlock2Margin={infoBlock2Margin}
			sumatoriaFinal={sumatoriaFinal}
			isMobileDevice={isMobileDevice}
		/>
	);
};

export const OrdenTemplate = ({
	factura,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
	isMobileDevice = false,
}) => {
	return (
		<>
			{isMobileDevice ? (
				<MobileLayout
					factura={factura}
					sucursal={sucursal}
					sumatoriaFinal={sumatoriaFinal}
				/>
			) : (
				<>
					<DesktopLayout
						factura={factura}
						sucursal={sucursal}
						sumatoriaFinal={sumatoriaFinal}
						infoBlock2Margin={infoBlock2Margin}
					/>
				</>
			)}
		</>
	);
};

const MobileLayout = ({
	factura,
	sucursal,
	sumatoriaFinal,
	isFactura = () => false,
	status,
}) => {
	return (
		<>
			<style>
				{`
					@page {
						size: 90mm 300mm !important;
						margin-top: 30px !important;
						margin-bottom: 0px !important;
						margin-left: 0px !important;
						margin-right: 0px !important;
					}
					.invoice {
						width: 125mm;
						font-size: large !important;
					 }
					 body {
						width: 100% !important;
					}
					.invoice-table {
						display: none;
					}
					.sm-invoice-table {
						display: block;
					}

					.invoice h3 {
						margin-bottom: 0.1rem;
					}
					.invoice h3.signature-title {
						font-size: 0.9rem;
						font-weight: 600 !important;
					}
					.client-info-title {
						font-size: 19px;
						font-weight: 600 !important;
					}
					.info-cliente-responsive .info-client__response {
						font-size: 18px;
						line-height: 18px !important;
					}
					.sucursal-info h3 {
						font-size: 23px !important;
						margin-bottom: 8px !important;
						font-weight: 600 !important;
					}
					.invoice-name {
						font-weight: 600 !important;
					}
					.sucursal-info div, .invoice-fecha {
						font-size: 18px !important;
					}
					.sm-invoice-table > div.row {
						font-size: 17.5px !important;
					}
					.invoice-itbis-info p, .invoice-itbis-info div {
						font-size: 19px !important;
						line-height: 22px !important;
					}
					// .summary-text {
					// 	font-size: 20px !important;
					// 	line-height: 20px !important;
					// }
					.info-cliente-responsive {
						line-height: 18px !important;
						margin-bottom: 1rem !important;
						font-size: 20px !important;
						margin-left: 5px !important;
					}
					@media(max-width: 575px){
						.info-client__response{
							font-size: 15.5px !important;
							text-transform: uppercase;
						}

    					.sucursal-name-sm{
    					    display:block;
   					 	}
   					 	.factura-logo{
   					     display:none;
   					 	}
   					 	.sucursal-name-lg{
   					     display:none;
   					 	}
						.cliente-direccion, .sucursal-name-sm{
							line-height: 20px !important;
							margin-bottom: 3px;
						}
						.sm-invoice-table > div.row {
							font-size: 15.5px !important;
					   	}
						   .justify-content-between :nth-child(2){
							text-align:left !important;
							max-width: 33% !important;
						}
					}

				`}
			</style>
			<div className="p-2 invoice recibo-print">
				<div className="clear-both p-t-30 p-b-10">
					<div className="sucursal-info sucursal-info__mobile">
						<h3 className="sucursal-name-sm">{sucursal.nombre}</h3>
						{sucursal.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={sucursal.logo[0].url}
								width="270px"
							/>
						) : factura.logo ? (
							<img
								alt="logo"
								className="mb-2 factura-logo"
								src={factura.logo[0].url}
								width="270px"
							/>
						) : (
							<h3
								className="sucursal-name-lg"
								style={{
									lineHeight: "16px",
									marginBottom: "3px",
								}}
							>
								{sucursal.nombre}
							</h3>
						)}
						<div
							className="mb-0"
							style={{ lineHeight: "16px", fontSize: "23px" }}
						>
							RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
						</div>
						<div
							className="mb-2"
							style={{ lineHeight: "16px", fontSize: "13px" }}
						>
							{sucursal.direccion}
						</div>
					</div>

					<div className="">
						<div
							className="mb-2 invoice-fecha d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							Fecha: {dayjs(factura.fecha).format("DD/MM/YYYY")}
							<br />
							{getDiasPlazo(
								factura.fecha,
								factura.fecha_vencimiento
							) > 0 ? (
								<>
									<span>
										Plazo:{" "}
										{getDiasPlazo(
											factura.fecha,
											factura.fecha_vencimiento
										) || 0}{" "}
										días
									</span>
									<br />
								</>
							) : (
								<>
									<span>Plazo: Contado</span>
									<br />
								</>
							)}
							{factura.tipo === "contado" &&
							factura.estado === "pendiente" ? (
								<span>Estado: Pendiente</span>
							) : (
								factura.tipo === "contado" &&
								factura.estado === "cancelada" && (
									<span>Estado: Cancelada</span>
								)
							)}
							{factura.estado === "pagada" && (
								<span>
									Método de pago:{" "}
									<span className="text-capitalize">
										{factura.tipo_pago !== ""
											? factura.tipo_pago
											: "Efectivo"}
									</span>
								</span>
							)}
						</div>
						<h3
							style={{
								fontSize: "18px",
								lineHeight: "14px",
								marginBottom: "3px",
							}}
							className="d-block invoice-name"
						>
							Orden de compra
						</h3>
						<div
							className="d-block"
							style={{ fontSize: "medium", lineHeight: "16px" }}
						>
							<span>NO.</span>
							{factura.numero_comprobante}
							<br />
							{factura.numeracion?.fecha_vencimiento && (
								<>
									<span>FV:</span>{" "}
									{dayjs(
										factura.numeracion?.fecha_vencimiento
									).format("DD/MM/YYYY")}
								</>
							)}
						</div>
						<span className="d-block client-info-title pt-2">
							{status}
						</span>
					</div>
				</div>

				<div className="info-cliente-responsive d-block">
					{factura.cliente && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Cliente:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.nombre}
							</div>
						</div>
					)}
					{factura.cliente?.identificacion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									RNC/Cédula:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.identificacion}
							</div>
						</div>
					)}
					{factura.cliente?.encargado && (
						<div className="row">
							<div className="col-4 text-right px-1 client-info-title">
								Responsable:
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.encargado}
							</div>
						</div>
					)}
					{factura.cliente?.telefono && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Teléfono:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "13px" }}
							>
								{factura.cliente?.telefono}
							</div>
						</div>
					)}
					{factura.cliente?.direccion && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Dirección:
								</span>
							</div>
							<div
								className="col-7 info-client__response"
								style={{ lineHeight: "23px" }}
							>
								{factura.cliente?.direccion}
							</div>
						</div>
					)}
					{factura.vendedor?.nombre && (
						<div className="row">
							<div className="col-4 text-right px-1">
								<span className="client-info-title">
									Vendedor:
								</span>
							</div>
							<div className="col-7 info-client__response">
								{limitVendedorFullName(
									factura.vendedor?.nombre,
									factura.vendedor?.apellido
								)}
							</div>
						</div>
					)}
				</div>

				<h3 className="table-title"></h3>
				{/* PRODUCTOS RESPONSIVE */}
				<div className="sm-invoice-table">
					<div className="row" style={{ fontWeight: "600" }}>
						<div className="col-5">DESCRIPCIÓN</div>
						<div className="col-3 px-0">IMPUESTOS</div>
						<div className="col-4" style={{ whiteSpace: "nowrap" }}>
							TOTAL
						</div>
					</div>

					{factura.productos?.map((producto) => {
						return (
							<div
								className="row"
								style={{
									fontSize: "small",
									marginBottom: "5px",
								}}
								key={producto.id}
							>
								<div
									className="col-5"
									style={{ lineHeight: "16px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>{" "}
									{formatCurrency(
										producto.precio_facturado *
											producto.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						);
					})}
				</div>

				<div className="summary pt-3">
					{factura.descuento > 0 && (
						<div className="row justify-content-between">
							<div
								className="col-2 summary-text"
								style={{
									maxWidth: "8rem",
									lineHeight: "16px",
									textAlign: "end",
									fontWeight: "600",
								}}
							>
								Descuento
							</div>
							<div
								className="col-2 text-right summary-text"
								style={{ maxWidth: "9rem", lineHeight: "16px" }}
							>
								{formatCurrency(factura.descuento, "$")}
							</div>
						</div>
					)}
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col-2 summary-text"
							style={{
								maxWidth: "8rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							Subtotal
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{ maxWidth: "9rem", lineHeight: "16px" }}
						>
							{formatCurrency(
								factura.subtotal - factura.descuento
							)}
						</div>
					</div>
					<div className="d-block">
						{Object.keys(sumatoriaFinal.impuestos).map(
							(impuesto, index) => {
								return (
									<div
										className="row justify-content-between"
										key={index}
									>
										<div
											className="col summary-text"
											style={{
												maxWidth: "8rem",
												lineHeight: "16px",
												fontWeight: "600",
											}}
										>
											{impuesto}
										</div>
										<div
											className="col-2 text-right summary-text"
											style={{
												maxWidth: "9rem",
												lineHeight: "16px",
											}}
										>
											{formatCurrency(
												sumatoriaFinal.impuestos[
													impuesto
												],
												"$"
											)}
										</div>
									</div>
								);
							}
						)}
					</div>

					<div className="row justify-content-between">
						<div
							className="col summary-text"
							style={{
								maxWidth: "11rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							TOTAL A PAGAR
						</div>
						<div
							className="col-2 text-right summary-text"
							style={{
								maxWidth: "9rem",
								lineHeight: "16px",
								fontWeight: "600",
							}}
						>
							{formatCurrency(factura.total, "$")}
						</div>
					</div>
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<hr className="mb-0" />
					)}

					<div className="invoice-itbis-info">
						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<p
								style={{
									lineHeight: "16px",
									fontSize: "small",
								}}
							>
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 16%"
								) && (
									<>
										<span>
											Items con I1 son gravados con 16%
											ITBIS
										</span>
										<br />
									</>
								)}
								{Object.keys(sumatoriaFinal.impuestos).includes(
									"Itbis 18%"
								) && (
									<span>
										Items con I2 son gravados con 18% ITBIS
									</span>
								)}
							</p>
						)}

						{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
							<div className="row justify-content-between">
								<div
									className="col-6 pr-1"
									style={{
										maxWidth: "11rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
										whiteSpace: "nowrap",
									}}
								>
									TOTAL IMPUESTOS
								</div>
								<div
									className="col-2"
									style={{
										maxWidth: "9rem",
										fontSize: "small",
										lineHeight: "16px",
										fontWeight: "600",
									}}
								>
									{formatCurrency(factura.impuestos, "$")}
								</div>
							</div>
						)}
					</div>
				</div>

				<hr className="d-block mt-0" />
				{factura.notas && (
					<>
						<p style={{ fontWeight: "600", fontSize: "1rem" }}>
							Notas:
						</p>
						<p
							className="col-12 pl-0 mb-3"
							style={{
								fontSize: "1rem",
								whiteSpace: "pre-line",
								lineHeight: "normal",
							}}
						>
							{factura.notas}
						</p>
					</>
				)}

				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 text-center mr-0 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<h3
						className="col-10 text-center ml-0 fw-600 mt-5 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>

				{factura.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{factura.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</>
	);
};

const DesktopLayout = ({
	factura,
	sucursal,
	infoBlock2Margin,
	sumatoriaFinal,
}) => {
	return (
		<div className="p-2 invoice recibo-print">
			<div className="clear-both p-t-30 p-b-10">
				<div className="float-sm-left sucursal-info">
					{sucursal.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={sucursal.logo[0].url}
							width="270px"
						/>
					) : factura.logo ? (
						<img
							alt="logo"
							className="mb-2 factura-logo"
							src={factura.logo[0].url}
							width="270px"
						/>
					) : (
						<h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
							{sucursal.nombre}
						</h3>
					)}
					<div
						className="mb-0 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
					</div>
					<div
						className="mb-2 mb-sm-0"
						style={{ lineHeight: "15px", fontSize: "13px" }}
					>
						{sucursal.direccion}
					</div>
				</div>

				<div className="float-sm-right text-sm-right">
					<div
						className="mb-2 invoice-fecha d-block d-sm-none"
						style={{ fontSize: "small", lineHeight: "15px" }}
					>
						Fecha: {dayjs(factura.fecha).format("DD/MM/YYYY")}
						{getDiasPlazo(
							factura.fecha,
							factura.fecha_vencimiento
						) > 0 ? (
							<>
								<br />
								<span>
									Plazo:{" "}
									{getDiasPlazo(
										factura.fecha,
										factura.fecha_vencimiento
									) || 0}{" "}
									días
								</span>
							</>
						) : (
							<>
								<br />
								<span>Plazo: Contado</span>
							</>
						)}
					</div>
					<h3
						className="d-block d-sm-none"
						style={{
							fontSize: "15px",
							lineHeight: "12px",
							marginBottom: "3px",
						}}
					>
						Orden de compra
					</h3>
					<div
						className="d-block d-sm-none"
						style={{ fontSize: "15px", lineHeight: "12px" }}
					>
						<span>No.: </span>
						{factura.numero_comprobante}
						<br />
					</div>
				</div>
			</div>

			<div className="info-cliente-responsive d-block d-sm-none">
				{factura.cliente && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Cliente:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.nombre}
						</div>
					</div>
				)}
				{factura.cliente?.identificacion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								RNC/Cédula:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.identificacion}
						</div>
					</div>
				)}
				{factura.cliente?.encargado && (
					<div className="row">
						<div className="col-4 text-right px-1 client-info-title">
							Responsable:
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.encargado}
						</div>
					</div>
				)}
				{factura.cliente?.telefono && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Teléfono:</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.telefono}
						</div>
					</div>
				)}
				{factura.cliente?.direccion && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">
								Dirección:
							</span>
						</div>
						<div className="col-7" style={{ lineHeight: "13px" }}>
							{factura.cliente?.direccion}
						</div>
					</div>
				)}
				{factura.vendedor && (
					<div className="row">
						<div className="col-4 text-right px-1">
							<span className="client-info-title">Vendedor:</span>
						</div>
						<div className="col-7">
							{`${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`}
						</div>
					</div>
				)}
			</div>

			<div className="clear-both info-row p-t-10 p-b-10 d-none d-sm-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table>
						<tbody id="info-block-1">
							{factura.cliente?.nombre && (
								<tr>
									<td
										width="120px"
										className="client-info-title text-uppercase text-right"
									>
										Cliente:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.nombre}
									</td>
								</tr>
							)}
							{factura.cliente?.identificacion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										RNC/Cédula:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.identificacion}
									</td>
								</tr>
							)}
							{factura.cliente?.encargado && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Responsable:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.encargado}
									</td>
								</tr>
							)}
							{factura.cliente?.telefono && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Teléfono:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.cliente?.telefono}
									</td>
								</tr>
							)}
							{factura.cliente?.direccion && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Dirección:
									</td>
									<td
										style={{
											lineHeight: "13px",
											paddingLeft: "10px",
										}}
									>
										{factura.cliente?.direccion}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div
					className="info-block"
					style={{ marginTop: infoBlock2Margin }}
				>
					<table>
						<tbody id="info-block-2">
							<tr>
								<td colSpan={2} style={{ paddingLeft: "18%" }}>
									<h3
										style={{
											fontSize: "0.9rem",
											marginBottom: "3px",
											paddingBottom: "10px",
										}}
									>
										Orden de compra
									</h3>
								</td>
							</tr>
							{factura.fecha && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Fecha:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(factura.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
								</tr>
							)}
							{factura.numero_comprobante && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										No.:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{factura.numero_comprobante}
									</td>
								</tr>
							)}
							{getDiasPlazo(
								factura.fecha,
								factura.fecha_vencimiento
							) > 0 ? (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{getDiasPlazo(
											factura.fecha,
											factura.fecha_vencimiento
										) || 0}{" "}
										días
									</td>
								</tr>
							) : (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Plazo:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										Contado
									</td>
								</tr>
							)}

							{factura.numeracion?.fecha_vencimiento && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										FV:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{dayjs(
											factura.numeracion
												?.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
								</tr>
							)}
							{factura.vendedor?.nombre && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Vendedor:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{limitVendedorFullName(
											`${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className="table-wrapper table-responsive">
				<h3 className="table-title"></h3>
				<table className="table d-none d-sm-table">
					<thead>
						<tr className="text-uppercase">
							<th width="12%">Código/Ref.</th>
							<th width="25%">Descripción</th>
							<th width="8%">Cantidad</th>
							<th width="8%">Unidad</th>
							<th>Importe</th>
							<th>Impuesto</th>
							<th>Precio</th>
							{factura.descuento > 0 && <th>Descuento</th>}
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{factura?.productos?.map((producto) => {
                            const descuento =
                            producto.cantidad *
                            (producto.importe +
                                producto.importe *
                                    producto.impuesto?.porcentaje) *
                            (producto.descuento / 100);

                        const descuentoReal =
                            (producto.importe *
                            (producto.descuento / 100))
                            * producto.cantidad;

							return (
								<tr key={producto.id}>
									<td>
										<div style={{ lineHeight: 1.2 }}>
											<div>
												{producto.producto?.referencia}
											</div>
										</div>
									</td>
									<td>
										{factura.tipo === "interna"
											? producto.nombre_producto
											: producto.producto?.nombre}
									</td>
									<td>{producto.cantidad}</td>
									<td>{producto.producto?.unidad}</td>
									<td>{formatCurrency(producto.importe)}</td>
									<td style={{ whiteSpace: "nowrap" }}>
										<span className="fw-600">
											{tipoITBIS(producto.impuesto)}{" "}
										</span>
										{formatCurrency(
											producto.precio_facturado -
                                            producto.importe
										)}
									</td>
									<td>
										{formatCurrency(producto.precio_facturado)}
									</td>
									{factura.descuento > 0 && (
										<td>
											{formatCurrency(descuentoReal)}
										</td>
									)}
									<td>{formatCurrency(producto.total - descuento)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{/* PRODUCTOS RESPONSIVE */}
			<div className="sm-invoice-table">
				<div className="row" style={{ fontWeight: "600" }}>
					<div className="col-5">DESCRIPCIÓN</div>
					<div className="col-3 px-0">IMPUESTOS</div>
					<div className="col-4 ">TOTAL</div>
				</div>

				{factura.productos?.map((producto) => {
					return (
						<>
							<div
								className="row"
								style={{
									fontSize: "smaller",
									marginBottom: "5px",
								}}
							>
								<div
									className="col-5"
									style={{ lineHeight: "13px" }}
								>
									{`${producto.cantidad} x ${formatCurrency(
										producto.precio_facturado,
										""
									)}`}
									<br />
									{producto.producto?.nombre}
									<br />
									REF: {producto.producto?.referencia}
								</div>
								<div className="col-3 px-0">
									<span className="fw-600">
										{tipoITBIS(producto.impuesto)}
									</span>
									{formatCurrency(
										producto.precio_facturado *
											producto.impuesto?.porcentaje,
										"$"
									)}
								</div>
								<div className="col-3 pr-0">
									{formatCurrency(producto.total, "$")}{" "}
								</div>
							</div>
						</>
					);
				})}
			</div>

			<div className="summary pt-3">
				{factura.descuento > 0 && (
					<div className="row justify-content-between justify-content-md-end">
						<div
							className="col-2 text-sm-right"
							style={{
								maxWidth: "8rem",
								textAlign: "end",
								fontWeight: "600",
							}}
						>
							Descuento
						</div>
						<div
							className="col-2 text-right"
							style={{ maxWidth: "9rem" }}
						>
							{formatCurrency(factura.descuento)}
						</div>
					</div>
				)}
				<div className="row justify-content-between justify-content-md-end">
					<div
						className="col-2 text-sm-right"
						style={{
							maxWidth: "8rem",
							fontWeight: "600",
						}}
					>
						Subtotal
					</div>
					<div
						className="col-2 text-right"
						style={{ maxWidth: "9rem" }}
					>
						{formatCurrency(factura.subtotal - factura.descuento)}
					</div>
				</div>
				<div className="d-block">
					{Object.keys(sumatoriaFinal.impuestos).map(
						(impuesto, index) => {
							return (
								<div
									className="row justify-content-between justify-content-md-end"
									key={index}
								>
									<div
										className="col col-md-2 text-sm-right"
										style={{
											maxWidth: "8rem",
											fontWeight: "600",
										}}
									>
										{impuesto}
									</div>
									<div
										className="col-2 text-right"
										style={{
											maxWidth: "9rem",
										}}
									>
										{formatCurrency(
											sumatoriaFinal.impuestos[impuesto],
											"$"
										)}
									</div>
								</div>
							);
						}
					)}
				</div>
				<div className="row justify-content-between justify-content-md-end">
					<div
						className="col col-md-2 text-sm-right"
						style={{
							maxWidth: "8rem",
							fontWeight: "600",
						}}
					>
						TOTAL
					</div>
					<div
						className="col-2 text-right"
						style={{
							maxWidth: "9rem",
							fontWeight: "600",
						}}
					>
						{formatCurrency(factura.total, "$")}
					</div>
				</div>
				{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
					<hr className="m-b-5" />
				)}

				<div className="text-sm-right invoice-itbis-info">
					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<p
							style={{
								fontSize: "small",
							}}
						>
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 16%"
							) && (
								<>
									<span>
										Items con I1 son gravados con 16% ITBIS
									</span>
									<br />
								</>
							)}
							{Object.keys(sumatoriaFinal.impuestos).includes(
								"Itbis 18%"
							) && (
								<span>
									Items con I2 son gravados con 18% ITBIS
								</span>
							)}
						</p>
					)}

					{Object.keys(sumatoriaFinal.impuestos).length > 1 && (
						<div className="row justify-content-between justify-content-md-end m-t-5">
							<div
								className="col col-md-6 pr-1"
								style={{
									maxWidth: "11rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								TOTAL IMPUESTOS
							</div>
							<div
								className="col-2"
								style={{
									maxWidth: "9rem",
									fontSize: "small",
									fontWeight: "600",
								}}
							>
								{formatCurrency(Object.values(sumatoriaFinal.impuestos).reduce((acc, imp) => acc + imp, 0))}
							</div>
						</div>
					)}
				</div>
			</div>

			<hr className="d-block d-sm-none mt-0 mb-4" />
			{factura.notas && (
				<>
					<p style={{ fontWeight: "600", fontSize: "1rem" }}>
						Notas:
					</p>
					<p
						className="col-12 col-md-8 pl-0 mb-3"
						style={{
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						{factura.notas}
					</p>
				</>
			)}

			<div className="footer-facturas">
				<div className="row justify-content-center mt-5">
					<h3
						className="col-10 col-sm-3 text-center mr-sm-2 mr-0 mt-sm-4 fw-600 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Elaborado por
					</h3>
					<div className="col-sm-2 d-none d-sm-block"></div>
					<h3
						className="col-10 col-sm-3 text-center ml-sm-2 ml-0 fw-600 mt-5 mt-sm-4 signature-title"
						style={{ borderTop: "1px solid #000" }}
					>
						Recibido por
					</h3>
				</div>
				{factura.plantilla?.pie_pagina && (
					<div
						className="col-12 pt-5 text-center d-flex justify-content-center"
						style={{
							fontWeight: "600",
							fontSize: "1rem",
							whiteSpace: "pre-line",
							lineHeight: "normal",
						}}
					>
						<p className="text-center pie-pagina">
							{factura.plantilla?.pie_pagina}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default PrintSingleOrden;
