import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import SelectAsync from "react-select/async";
import SelectCreateAsync from "react-select/async-creatable";

import { apiRequest } from '../../hooks/apiRequest';
import { formasPagoCompras, getLink, isVendedor, tiposGastosCompras, triggerEvent } from '../../utils/helpers';
import { Link } from "react-router-dom";

const Fields = ({contacto, contactos, errors, terminos, compra, toggleModal, updateContacto, setTipoDescuento, tipoDescuento }) => {
    const [state, setState] = useState({
        contacto_id: false,
        cats: compra.categorias ? compra.categorias.map(c => c.id).join(',') : '',
        fecha: compra.fecha ? dayjs(compra.fecha).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        vencimiento: compra.fechaVencimiento ? dayjs(compra.fechaVencimiento).format('YYYY-MM-DD') :  dayjs().format('YYYY-MM-DD'),
        tipo: compra.tipo,
        almacen: null,
    });

    const onChangeDate = (e) => {
        setState({
            ...state,
            fecha: e.target.value,
        });
        triggerEvent('actualizar-fecha-compra', {fecha: e.target.value});
    };

    const onChangeInput = async (name, value) => {
        await setState({
            ...state,
            [name]: value,
        });

        if (name === 'contacto_id') {
            updateContacto(value);
        }
    };

    const onChangeMultiSelect = (name, values) => {
        setState({
            ...state,
            [name]: values?.map(v => v.value).join(','),
        });
    }

    const onChangePlazo = (value) => {
        const termino = terminos.filter(t => t.id === value)[0];

        setState({
            ...state,
            plazo: value,
            vencimiento: dayjs(state.fecha).add(termino.dias, 'days').format('YYYY-MM-DD'),
        });
    }

    const getOptions = async (url = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]}));
        }
    };

    const clientLabel = (cliente) => {
		return (
			<span
				className="row px-0 align-items-center"
				style={{ width: "-webkit-fill-available" }}
			>
				<span
					className="col-10"
					style={{ minWidth: "100px", wordBreak: "break-all", overflow: "hidden" }}
				>
					{cliente.label}
				</span>
				{!isVendedor() && (
					<Link
						to={getLink(
							`/contactos/${cliente.value}/transacciones`
						)}
						className="producto color-primary"
						target="_blank"
						onClick={() => {
							localStorage.setItem("contacto", JSON.stringify({id: cliente.value, nombre: cliente.label}));
						}}
					>
						<i className="fas fa-eye"></i>
					</Link>
				)}
			</span>
		);
	};

    const {cats, fecha, vencimiento} = state;

    return (<>
        <div className="col">
            <div
                className="form-group"
                style={{ marginBottom: "0.3rem" }}
            >
                <label htmlFor="inputContacto" className="">
                    Suplidor
                </label>
                {contactos && (
                    <div className="row">
                        <Select
                            key="contacato_id"
                            placeholder="Seleccionar..."
                            name="contacto_id"
                            onChange={(e) =>
                                onChangeInput("contacto_id", e.value)
                            }
                            value={contacto ? {label: contacto.nombre, value: contacto.id} : ''}
                            options={contactos.filter(c => c.tipo?.indexOf('Proveedor') > -1).map((contacto) => ({label: contacto.nombre, value: contacto.id}))}
                            className="col"
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    height: "38px",
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    display: "flex",
                                    width: "100%",
                                }),
                            }}
                            formatOptionLabel={(option) =>
                                clientLabel(option)
                            }
                        />
                        <div className="tooltip-self color-primary d-inline-block mt-2">
                            <i className="fas fa-plus-circle color-primary"
                                style={{
                                    fontSize: "1.25rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => toggleModal('CreateContacto')}
                            ></i>
                            <span className="tootip-text tooltip-right">
                                Crear Contacto
                            </span>
                        </div>
                        {contacto && (
                            <div
                                className="tooltip-self color-primary d-inline-block ml-2"
                                style={{ alignSelf: "center" }}
                            >
                                <i
                                    className="fas fa-edit color-primary"
                                    style={{
                                        fontSize: "1.25rem",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => toggleModal('EditContacto')}
                                ></i>
                                <span className="tootip-text tooltip-right">
                                    Editar Contacto
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {errors.suplidor_id && (
                    <small className="help-blockParams form-Text">
                        {errors.suplidor_id[0]}
                    </small>
                )}
            </div>
            <div className="form-group row mt-3">
                <div className="col-md-6">
                    <label className='label form-control-label'>
                        RNC o Cédula:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='rnc'
                        value={contacto?.identificacion || ''}
                        readOnly
                    />
                </div>
                <div className="col-md-6">
                    <label className='label form-control-label'>
                        Teléfono:
                    </label>
                    <input
                        type='text'
                        className='text-input form-control'
                        id='telefono'
                        value={contacto?.telefono || ''}
                        readOnly
                    />
                </div>
            </div>
            <div className="form-group">
                <label className='label form-control-label'>
                    Dirección:
                </label>
                <input
                    type='direccion'
                    className='text-input form-control'
                    id='direccion'
                    value={contacto?.direccion || ''}
                    readOnly
                    disabled
                />
            </div>
        </div>
        <div className="col">
            <div className='form-group'>
                <label className='label form-control-label'>
                    NCF:
                </label>
                <input
                    type='text'
                    className='text-input form-control'
                    id='ncf'
                    name='ncf'
                    defaultValue={compra.ncf}
                />
                {errors.ncf && (
                    <small className='help-blockParams form-Text'>
                        {errors.ncf[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    No. Factura:
                </label>
                <input
                    type='text'
                    className='text-input form-control'
                    id='numero'
                    name='numero'
                    defaultValue={compra.numero}
                />
                {errors.numero_factura && (
                    <small className='help-blockParams form-Text'>
                        {errors.numero_factura[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Categorías:
                </label>

                <SelectCreateAsync
                    placeholder="Seleccionar..."Create
                    key="categorias"
                    id='categorias'
                    isMulti
                    required
                    cacheOptions
                    defaultOptions
                    onChange={(values) => onChangeMultiSelect('cats', values)}
                    defaultValue={compra.categorias && compra.categorias.map(c => ({label: c.nombre, value: c.id}))}
                    loadOptions={(e) => {
                        return getOptions("taxonomias?relacion=compras&buscar="+e);
                    }}
                />

                <input type="hidden" name="categorias_compras" value={cats} />

                {errors["categorias_compras"] && (
                    <small className='help-blockParams form-Text'>
                        {errors["categorias_gastos"][0]}
                    </small>
                )}
            </div>
        </div>
        <div className="col">
            <div className='form-group'>
                <label className='label form-control-label'>
                    Fecha:
                </label>
                <input
                    type='date'
                    className='text-input form-control'
                    id='fecha'
                    name='fecha'
                    value={fecha}
                    onChange={onChangeDate}
                />
                {errors.fecha && (
                    <small className='help-blockParams form-Text'>
                        {errors.fecha[0]}
                    </small>
                )}
            </div>

            <div className='form-group'>
                <label className='label form-control-label'>
                    Plazo de Pago:
                </label>
                <SelectAsync
                    id="plazo_id"
                    key="plazo_id"
                    cacheOptions
                    defaultOptions
                    loadOptions={() => getOptions("terminos-pago")}
                    defaultValue={compra.plazo && {value: compra.plazo.id, label: compra.plazo.nombre}}
                    name="plazo_id"
                    onChange={e => onChangePlazo(e.value)}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            height: "38px",
                        }),
                    }}
                />
                {errors.plazo_id && (
                    <small className="help-blockParams form-Text">
                        {errors.plazo_id[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Vencimiento:
                </label>
                <input
                    type='date'
                    className='text-input form-control'
                    id='vencimiento'
                    name='fecha_vencimiento'
                    value={vencimiento}
                    onChange={e => onChangeInput('vencimiento', e.target.value)}
                />
                {errors.vencimiento && (
                    <small className='help-blockParams form-Text'>
                        {errors.vencimiento[0]}
                    </small>
                )}
            </div>
        </div>
        <div className="col">
            <div className='form-group'>
                <label className='label form-control-label'>
                    Forma de Pago:
                </label>
                <Select
                    placeholder="Seleccionar..."
                    id='forma_de_pago'
                    key="forma_de_pago"
                    isClearable
                    defaultValue={compra.formaPago && {label: compra.formaPago, value: compra.formaPago}}
                    name='forma_de_pago'
                    options={formasPagoCompras.map(f => ({label: f, value: f}))}
                />
                {errors.forma_de_pago && (
                    <small className='help-blockParams form-Text'>
                        {errors.forma_de_pago[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Tipo de Gasto:
                </label>
                <Select
                    placeholder="Seleccionar..."
                    key='tipo_compra'
                    isClearable
                    name='tipo_gasto'
                    defaultValue={compra.tipoGasto && {label: compra.tipoGasto, value: compra.tipoGasto}}
                    options={tiposGastosCompras.map(f => ({label: f, value: f}))}
                />

                {errors.tipo_compra && (
                    <small className='help-blockParams form-Text'>
                        {errors.tipo_compra[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Aplicar descuentos por:
                </label>
                <Select
                    placeholder="Seleccionar..."
                    key='tipo_descuento'
                    onChange={e => setTipoDescuento(e.value ?? 'Porcentaje')}
                    defaultValue={(tipoDescuento && {label: tipoDescuento, value: tipoDescuento}) || {label: tipoDescuento, value: tipoDescuento}}
                    name='tipo_descuento'
                    options={[
                        {value: 'Porcentaje', label: 'Porcentaje'},
                        {value: 'Monto Fijo', label: 'Monto Fijo'},
                    ]}
                />
                {errors.tipo_descuento && (
                    <small className='help-blockParams form-Text'>
                        {errors.tipo_descuento[0]}
                    </small>
                )}
            </div>
        </div>
    </>)
}

export default Fields;
