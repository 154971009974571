/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	formatCurrency,
	getSucursal,
	money,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";

const BalanceReport = () => {
	const { data, sucursal, queryParams } = useData();

    const utilidadBruta = data.totalVentasPeriodo - data.totalMenosCostoDeVenta;
    const utilidadBrutaProforma = data.totalProformas - data.totalMenosCostoDeVentaProforma;
    const utilidadBrutaComprobante = data.totalFacturas - data.totalMenosCostoDeVentaComprobante;

    console.log({
        utilidadBruta,
        utilidadBrutaProforma,
        utilidadBrutaComprobante,
        totalProformas: data.totalProformas,
        totalFacturas: data.totalFacturas,
        porcentajeFacturas: utilidadBrutaComprobante/data.totalFacturas,
        porcentajeProformas: utilidadBrutaProforma/data.totalFacturas,
    });

	// TODO en el lado izquierdo hacer un resumen de las ventas de los vendedores.
	return (
		<>
			{/* SUCURSAL INFO */}
			<div className="row mb-3">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte de balance de la compañía
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
			</div>
			{/* CUENTAS TOTALS */}
			<div
				className="clear-both info-row p-t-10 p-b-10 d-flex justify-content-center"
				style={{ fontSize: "1.3rem" }}
			>
				<div className="info-block" style={{ width: "75%" }}>
					<table className="w-100">
						<tbody id="info-block-1">
							{/* TOTAL CUENTAS X COBRAR */}
							<tr>
								<td width="320px" className="text-uppercase">
									TOTAL CUENTAS X COBRAR:
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.totalCxC)}
								</td>
							</tr>

							{/* VALOR DEL INVENTARIO */}
							<tr>
								<td className="text-uppercase">
									VALOR DEL INVENTARIO:
								</td>
								<td>=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.totalProductos)}
								</td>
							</tr>

							{/* BLACK LINE */}
							<tr>
								<td className="text-uppercase"></td>
								<td colSpan={2}>
									<div
										style={{ border: "solid 1px black" }}
									></div>
								</td>
							</tr>

							{/* TOTAL CXC + VALOR INVENTARIO */}
							<tr>
								<td
									width="320px"
									className="text-uppercase"
								></td>
								<td width="120px"></td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
                                        parseFloat(data.totalCxC) + parseFloat(data.totalProductos)
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<table className="mt-3 w-100">
						<tbody id="info-block-2">
							{/* TOTAL CXP */}
							<tr>
								<td width="320px" className="text-uppercase">
									TOTAL CUENTAS X PAGAR:
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(money(data.totalCxP))}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className="mt-4 d-flex justify-content-center">
				<div
					className="info-block px-2"
					style={{
						width: "76%",
						border: "1px solid black",
						fontSize: "1.3rem",
					}}
				>
					<table className="w-100 px-2">
						<tbody id="info-block-1">
							{/* VENTAS DEL PERIODO */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									VENTAS DEL PERIODO
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{formatCurrency(
										data.totalVentasPeriodo
									)}
								</td>
							</tr>
							{/* TOTAL ITBIS 16% */}
							{data.totalITBIS16 > 0 && (
								<tr>
									<td
										width="370px"
										className="text-uppercase"
									>
										ITBIS 16%
									</td>
									<td width="120px">=</td>
									<td
										style={{
											lineHeight: "13px",
											paddingRight: "30px",
											textAlign: "end",
										}}
									>
										{formatCurrency(
											money(data.totalITBIS16)
										)}
									</td>
								</tr>
							)}

							{/* TOTAL ITBIS 18% */}
							{data.totalITBIS18 > 0 && (
								<tr>
									<td
										width="370px"
										className="text-uppercase"
									>
										ITBIS 18%
									</td>
									<td width="120px">=</td>
									<td
										style={{
											lineHeight: "13px",
											paddingRight: "30px",
											textAlign: "end",
										}}
									>
										{formatCurrency(
											data.totalITBIS18
										)}
									</td>
								</tr>
							)}
							{/* TOTAL ITBIS */}
							{data.totalITBIS > 0 && (
								<tr>
									<td
										width="370px"
										className="text-uppercase"
									>
										TOTAL ITBIS
									</td>
									<td width="120px">=</td>
									<td
										style={{
											lineHeight: "13px",
											paddingRight: "30px",
											textAlign: "end",
										}}
									>
										{formatCurrency(money(data.totalITBIS))}
									</td>
								</tr>
							)}

							{/* SEPARATOR */}
							<tr>
								<td></td>
							</tr>
							<tr>
								<td></td>
							</tr>
							<tr>
								<td></td>
							</tr>
							{/* END SEPARATORS */}

							{/* MENOS COSTO DE VENTAS */}
							<tr>
								<td className="text-uppercase">
									MENOS COSTO DE VENTAS
								</td>
								<td>=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalMenosCostoDeVenta)
									)}
								</td>
							</tr>

							{/* BLACK LINE */}
							<tr>
								<td className="text-uppercase"></td>
								<td colSpan={2}>
									<div
										style={{ border: "solid 1px black" }}
									></div>
								</td>
							</tr>

							{/* UTILIDAD BRUTA */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									UTILIDAD BRUTA DEL PERIODO
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{formatCurrency(
										money(
											utilidadBruta
										)
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<table className="mt-3 w-100 px-2">
						<tbody id="info-block-2">
							{/* % de UTILIDAD/COSTO */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									% de UTILIDAD SOBRE LA VENTA
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{money((utilidadBruta / data.totalVentasPeriodo) * 100
									)}
									%
								</td>
							</tr>
							<tr></tr>
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600 "
									style={{ paddingTop: "15px" }}
								>
									FACTURAS
								</td>
								<td
									width="120px"
									className="fw-600"
									style={{ paddingTop: "15px" }}
								>
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
										paddingTop: "15px",
									}}
									className="fw-600"
								>
									{formatCurrency(data.totalFacturas)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									CANTIDAD
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{data.cantidadFacturas}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									ITBIS 18%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.facturaTotalITBIS18)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									ITBIS 16%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.facturaTotalITBIS16)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									TOTAL ITBIS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										data.facturaTotalITBIS18 +
											data.facturaTotalITBIS16
									)}
								</td>
							</tr>

                            <tr>
								<td></td>
							</tr>

                            {/* MENOS COSTO DE VENTAS */}
							<tr>
								<td className="text-uppercase">
									MENOS COSTO DE VENTAS
								</td>
								<td>=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalMenosCostoDeVentaComprobante)
									)}
								</td>
							</tr>


							{/* BLACK LINE */}
							<tr>
								<td className="text-uppercase"></td>
								<td colSpan={2}>
									<div
										style={{ border: "solid 1px black" }}
									></div>
								</td>
							</tr>

							{/* UTILIDAD BRUTA */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									UTILIDAD BRUTA DEL PERIODO
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{formatCurrency(
										money(
											utilidadBrutaComprobante
										)
									)}
								</td>
							</tr>

                            <tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									% de UTILIDAD SOBRE LA VENTA
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{money((utilidadBrutaComprobante / data.totalFacturas) * 100
									)}
									%
								</td>
							</tr>

							<br />
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
									style={{ paddingTop: "15px" }}
								>
									PROFORMAS
								</td>
								<td
									width="120px"
									style={{ paddingTop: "15px" }}
								>
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
										paddingTop: "15px",
									}}
                                    className="fw-600"
								>
									{formatCurrency(data.totalProformas)}
								</td>
							</tr>
                            <tr>
								<td width="370px" className="text-uppercase">
									CANTIDAD
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{data.cantidadProformas}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									ITBIS 18%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.proformaTotalITBIS18)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									ITBIS 16%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.proformaTotalITBIS16)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									TOTAL ITBIS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										data.proformaTotalITBIS18 +
											data.proformaTotalITBIS16
									)}
								</td>
							</tr>

                            <tr>
								<td></td>
							</tr>

                            {/* MENOS COSTO DE VENTAS */}
							<tr>
								<td className="text-uppercase">
									MENOS COSTO DE VENTAS
								</td>
								<td>=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalMenosCostoDeVentaProforma)
									)}
								</td>
							</tr>


							{/* BLACK LINE */}
							<tr>
								<td className="text-uppercase"></td>
								<td colSpan={2}>
									<div
										style={{ border: "solid 1px black" }}
									></div>
								</td>
							</tr>

							{/* UTILIDAD BRUTA */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									UTILIDAD BRUTA DEL PERIODO
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{formatCurrency(
										money(
											utilidadBrutaProforma
										)
									)}
								</td>
							</tr>

                            <tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									% de UTILIDAD SOBRE LA VENTA
								</td>
								<td width="120px" className="fw-600">
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{money((utilidadBrutaProforma / data.totalProformas) * 100
									)}
									%
								</td>
							</tr>

                            <br />
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
									style={{ paddingTop: "15px" }}
								>
									CANCELADAS
								</td>
								<td
									width="120px"
									style={{ paddingTop: "15px" }}
								>
									=
								</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
										paddingTop: "15px",
									}}
                                    className="fw-600"
								>
									{formatCurrency(data.totalVentasCanceladas)}
								</td>
							</tr>
                            <tr>
								<td width="370px" className="text-uppercase">
									CANTIDAD
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{data.cantidadCanceladas}
								</td>
							</tr>
						</tbody>
					</table>

					{/* SEGUNDA SECCIÓN */}
					<table
						className="mt-3 w-100"
						style={{ borderTop: "1px solid black" }}
					>
						<tbody id="info-block-2">
							{/* DEVOLUCIONES/VENTAS */}
							<tr>
								<td width="370px" className="text-uppercase">
									NOTAS DE CRÉDITOS VENTAS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalNotasCredito)
									)}
								</td>
							</tr>
							{/* DESCUENTOS EN FACTURACIÓN */}
							<tr>
								<td width="370px" className="text-uppercase">
									DESCUENTOS EN FACTURACIÓN
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalDescuentosFacturacion)
									)}
								</td>
							</tr>
							{/* DESCUENTOS POST FACTURACIÓN */}
							{/* <tr>
								<td width="370px" className="text-uppercase">
									DESCUENTOS POST FACTURACIÓN
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(0)}
								</td>
							</tr> */}
						</tbody>
					</table>

					{/* TERCERA SECCIÓN */}
					<table
						className="mt-3 w-100"
						style={{ borderTop: "1px solid black" }}
					>
						<tbody id="info-block-2">
							{/* INGRESOS DEL PERIODO */}
							<tr>
								<td width="370px" className="text-uppercase">
									INGRESOS DEL PERIODO
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.totalIngresosPeriodo)
									)}
								</td>
							</tr>
						</tbody>
					</table>

					{/* CUARTA SECCIÓN */}
					<table
						className="mt-3 w-100"
						style={{ borderTop: "1px solid black" }}
					>
						<tbody id="info-block-2">
							{/* COMPRAS DEL PERIODO */}
							<tr>
								<td
									width="370px"
									className="text-uppercase fw-600"
								>
									COMPRAS DEL PERIODO
								</td>
								<td width="120px" className="fw-600">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
									className="fw-600"
								>
									{formatCurrency(
										money(data.totalComprasPeriodo)
									)}
								</td>
							</tr>
							<tr>
								<td
									width="370px"
									className="text-uppercase"
								>
									ITBIS 18%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.compraTotalITBIS18)
									)}
								</td>
							</tr>
							<tr>
								<td
									width="370px"
									className="text-uppercase"
								>
									ITBIS 16%
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.compraTotalITBIS16)
									)}
								</td>
							</tr>
							<tr>
								<td
									width="370px"
									className="text-uppercase"
								>
									TOTAL ITBIS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(data.compraTotalITBIS16 + data.compraTotalITBIS18)
									)}
								</td>
							</tr>
							<br />
							{/* COMPRAS PRODUCTOS */}
							<tr>
								<td width="370px" className="text-uppercase">
									COMPRAS PRODUCTOS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(
											data.totalCompraProductos
										)
									)}
								</td>
							</tr>
							{/* COMPRAS ITEMS */}
							<tr>
								<td width="370px" className="text-uppercase">
									COMPRAS INTERNAS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(
											data.totalCompraItems
										)
									)}
								</td>
							</tr>
							{/* COMPRAS SUMINISTROS */}
							<tr>
								<td width="370px" className="text-uppercase">
									COMPRAS SUMINISTROS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(
											data.totalCompraSuministros
										)
									)}
								</td>
							</tr>
						</tbody>
					</table>
					{/* QUINTA SECCIÓN */}
					<table
						className="mt-3 w-100"
						style={{ borderTop: "1px solid black" }}
					>
						<tbody id="info-block-2">
							<tr>
								<td width="370px" className="text-uppercase">
									NOTAS DE CRÉDITO COMPRAS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										money(
											data.totalComprasPeriodoCanceladas
										)
									)}
								</td>
							</tr>
							{/* DESCUENTOS/COMPRAS */}
							{/* <tr>
								<td width="370px" className="text-uppercase">
									DESCUENTOS/COMPRAS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(0)}
								</td>
							</tr> */}
							{/* PAGOS A SUPLIDORES */}
							<tr>
								<td width="370px" className="text-uppercase">
									PAGOS A SUPLIDORES
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.totalPagosCompras)}
								</td>
							</tr>
						</tbody>
					</table>
					{/* QUINTA SECCION */}
					<table
						className="mt-3 w-100"
						style={{ borderTop: "1px solid black" }}
					>
						<tbody id="info-block-2">
							{/* NOMINA */}
							<tr>
								<td width="370px" className="text-uppercase">
									NOMINA
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.totalNomina)}
								</td>
							</tr>
							{/* GASTOS INTERNOS */}
							<tr>
								<td width="370px" className="text-uppercase">
									GASTOS INTERNOS
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(data.totalGastoInterno)}
								</td>
							</tr>
							<tr>
								<td width="370px" className="text-uppercase">
									VALOR DE INVENTARIO INTERNO
								</td>
								<td width="120px">=</td>
								<td
									style={{
										lineHeight: "13px",
										paddingRight: "30px",
										textAlign: "end",
									}}
								>
									{formatCurrency(
										data.totalInventarioInterno
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

const useData = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [sucursal, setSucursal] = useState({});
	const [data, setData] = useState({
		totalCxC: 0,
		totalProductos: 0,
		totalCxP: 0,
		totalPagosCompras: 0,
		totalComprasPeriodo: 0,
		totalComprasPeriodoCanceladas: 0,
		totalVentasCanceladas: 0,
		totalVentasPeriodo: 0,
		totalIngresosPeriodo: 0,
		totalDescuentosFacturacion: 0,
		totalMenosCostoDeVenta: 0,
		totalMenosCostoDeVentaProforma: 0,
		totalMenosCostoDeVentaComprobante: 0,
		totalITBIS18: 0,
		totalITBIS16: 0,
		totalFacturas: 0,
		totalProformas: 0,
	});

	useEffect(() => {
		let filterParams = "";
		if (queryParams.get("desde")) {
			filterParams += `?desde=${queryParams.get("desde")}`;
		}
		if (queryParams.get("hasta")) {
			filterParams += `&hasta=${queryParams.get("hasta")}`;
		}

		(async () => {
			const res = await request(`reportes/balance${location.search}`);

			if (!res.success) {
				toast("error obteniendo data", "error");
				return;
			}

			setData(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		})();

		const sucursalInfo = JSON.parse(localStorage.getItem("user"));
		setSucursal(
			sucursalInfo.sucursales.find(
				(sucursal) => sucursal.id === getSucursal()
			)
		);
	}, []);

	return React.useMemo(
		() => ({ data, sucursal, queryParams }),
		[data, sucursal]
	);
};

export default BalanceReport;
