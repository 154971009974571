import React, { useState } from 'react';

export default function DocumentoItem({documento, existing, id}) {
    const [editando, setEditando] = useState(0);
    const [url, setUrl] = useState(documento.url);
    const [filename, setFilename] = useState();

    return (
        <div className="row form-group">
            <div className="col col-md-5 col-sm-12">
                <div className="col col-md-12">
                    <label
						htmlFor="nombre_documento"
						className="label form-control-label"
					>
						Nombre:
					</label>
                </div>
                <input type="text" name={`documentos[${documento.id}][nombre]`} className='form-control' defaultValue={url ? (documento.nombre ?? `documento_${documento.id}`) : ""} />
            </div>
            <div className="col col-md-7 pt-4">
                <div className="col col-md-12">
                    {editando ? (<input type="hidden" name={`documentos[${documento.id}][borrar]`} value={true}/>) : ""}
                    <input type='file' className='form-control-file' style={{display: "none"}} name={`documentos[${documento.id}][archivo]`} placeholder={"Subir archivo"} onChange={(e) => {
                        if (id < existing) setEditando(1);

                        if (e.target.value) {
                            const filename = e.target.value.replace(/^.*[\\/]/, '');
                            setUrl(filename);
                            setFilename(filename);
                        } else {
                            setUrl(documento.url);
                            setFilename();
                        }
                    }} id={`files_${documento.id}`} />
                    <label className='form-control-file btn btn-light' htmlFor={`files_${documento.id}`}>{url ? ((filename ?? documento.nombre) ?? `documento_${documento.id}`) : "Ningún documento seleccionado"}</label>
                </div>
                {/*<div className="col col-md-4">
                    <button className='btn'><i class="fas fa-trash"></i></button>
                </div>*/}
            </div>

        </div>
    );
}

function loadURLToInputFiled(url, documento){
    getImgURL(url, (imgBlob)=>{
        console.log(imgBlob);
      
      
    })
}

// xmlHTTP return blob respond
function getImgURL(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}