/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useEffect,
	useState,
	useRef,
} from "react";
import Loader from "../../../general/Loader";
import { request } from "../../../../utils/request";
import {
	formatCurrency,
	getLink,
	getVendedoresForDropdown,
	isPastDate,
	isVendedor,
	money,
	toast,
	validarPINSeguridad,
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";

import Create from "../../../Contactos/Create";
import Edit from "../../../Contactos/Edit";
import Modal from "../../../general/Modal";
import ProductosFactura from "./ProductosFactura";
import ProductosFacturaMobile from "./ProductosFacturaMobile";
import Detalles from "./Detalles";
import dayjs from "dayjs";
import { auth } from "../../../../utils/auth";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { PagoModalFooter } from "..";
import PagoForm from "../../Components/PagoForm";
import FileLoader from "../../../general/FileLoader";

import { calcularDescuento, calcularExentoGrabado, calcularImpuestos, calcularSubtotal, calcularTotal, calcularTotalImpuestos, templateProducto } from "../../Components/utils";

const canSaveAndPay = (isEdit, saving, fecha, tienePagos = false) =>
	(!tienePagos && !saving && isEdit && !isPastDate(fecha)) ||
	(!tienePagos && !isEdit && !saving && !isPastDate(fecha));

const Form = ({ facturaActual, isEdit, isRecovery, fromPlantilla, setFacturaGuardada }) => {
	const [fromOrden] = useLocalStorage("fromOrden");
	const history = useHistory();

	const formRef = useRef();
	const [loading, setLoading] = useState(true);

	//Cosas generales como formulario
	const [form, setForm] = useState({
		factura: {},
		fecha: dayjs().format("YYYY-MM-DD"),
		fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
		notas: "",
		categorias: [],
		prevTotal: 0,
		porCobrar: 0,
	});

    const [contactoId, setContactoId] = useState();
    const [facturaProductos, setFacturaProductos] = useState([
        templateProducto(Date.now())
    ]);
    const [comprobante, setComprobante] = useState({});
    const [plazo, setPlazo] = useState({});
    const [errors, setErrors] = useState({});

	const [showCreateContactoModal, setShowCreateContactoModal] =
		useState(false);
	const [showEditContactoModal, setShowEditContactoModal] = useState(false);
	const [showPINModal, setShowPINModal] = useState(false);
	const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [totalitbis, setTotalItbis] = useState(0);

	// Opciones de los Dropdown
	const [dropdownOptions, setDropdownOptions] = useState({
		contactos: [],
		terminos: [],
		productos: [],
		vendedores: [],
		rutas: [],
		grupos: [],
		impuestos: [],
		categorias: [],
	});

	const [saving, setSaving] = useState(false);
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");
	const [pinSeguridad, setPinSeguridad] = useState("");
	const [pagoInfo, setPagoInfo] = useState({
		pago: null,
		tipo_pago: "efectivo",
		terminal_tarjeta: "",
		tipo: "visa",
		banco: "",
		num_aprobacion: "",
		num_cheque: "",
		num_transferencia: "",
		num_deposito: "",
		num_transaccion: "",
        usarRetencion: false,
	});
	const [tipoImpresionAfterPago, setTipoImpresionAfterPago] =
		useState("normal");

	//Calculos Finales
	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		subtotal: 0,
		descuento: 0,
		impuestos: {},
		exentoGrabado: {},
		total: 0,
	});

	//Para llenar inputs de contacto (Cliente)
	const [contactoInfo, setContactoInfo] = useState({});

    const resetFacturaProductos = () => {
        setFacturaProductos([
            templateProducto(Date.now())
        ]);
    }

	const toggleCreateContactoModal = async (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowCreateContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		await getContactosForDropdown();

        setContactoId(contacto.id);
	};

	const toggleEditContactoModal = (e, contacto = null) => {
		if (e) e.preventDefault();
		setShowEditContactoModal((state) => !state);

		if (!contacto || !Object.keys(contacto).includes("id")) return;

		getContactosForDropdown();

		setContactoId(contacto.id);

		changeContactInfo(contacto.id, false);
	};

	const changeContactInfo = (contacto, clear = true) => {
		if (!contacto) return;

		request(`info-contacto/${contacto}`).then(({ data }) => {
			const vendedor = isVendedor()
				? {
						value: auth.getUser().empleado?.id,
						label: `${auth.getUser().empleado?.codigo} ${
							auth.getUser().empleado?.nombre
						} ${auth.getUser().empleado?.apellido}`,
				  }
				: {
						value: data.vendedor?.id,
						label: `${data.vendedor?.codigo} ${data.vendedor?.nombre} ${data.vendedor?.apellido}`,
				  };

            const contact = {
				...data,
				vendedor: vendedor.value ? vendedor : null,
				ruta: data.ruta
					? { value: data.ruta?.id, label: data.ruta?.nombre }
					: null,
				celular: data.celular,
				credito_disponible_actual: parseFloat(data.credito_disponible),
			};

			setContactoInfo(contact);


            if (clear) {
                changePlazo(dropdownOptions?.terminos[0]);
                resetFacturaProductos();
            }

            aplicarLimiteFecha();

            setFacturaGuardada(prev => ({
                ...prev,
                cliente: {
                    ...contact,
                    ruta: data.ruta
                        ? { value: data.ruta?.id, label: data.ruta?.nombre }
                        : null,
                },
                vendedor: vendedor.value ? vendedor : null,
                ruta: data.ruta
					? { value: data.ruta?.id, label: data.ruta?.nombre }
					: null,
            }));
		});
	};

	const handleInputChange = (value, field) => {
		setForm((form) => ({
			...form,
			[field]: value,
		}));

        if (Array.isArray(value)) {
            setFacturaGuardada(prev => ({
                ...prev,
                [field]: value.map(v => ({id: v?.value, nombre: v?.label})),
            }));
        } else {
            setFacturaGuardada(prev => ({
                ...prev,
                [field]: {id: value?.value, nombre: value?.label},
            }));
        }
	};

	const handleDetalleChange = (value, field) => {
		setContactoInfo((state) => ({ ...state, [field]: value }));
        setFacturaGuardada(prev => ({
            ...prev,
            [field]: value,
        }));
	};

	const aplicarLimiteFecha = () => {
		if (!contactoInfo.plazo) return;

		return dayjs(form.fecha)
			.add(contactoInfo.plazo?.dias, "day")
			.format("YYYY-MM-DD");
	};

	// Funciones para traer data de los dropdowns
	const getDropdownData = () => {
		request("facturas/create").then((res) => {
			setDropdownOptions((options) => ({
				...options,
				productos: res.data?.productos,
				rutas: res.data?.rutas,
				terminos: res.data?.terminos,
				comprobantes: res.data?.comprobantes,
				impuestos: res.data?.impuestos,
				categorias: res.data?.categorias,
			}));

            if (!isEdit && !fromPlantilla) {
                changePlazo(res.data?.terminos[0]);
            }
		});

		getVendedoresForDropdown().then((data) => {
			setDropdownOptions((options) => ({
				...options,
				vendedores: data.filter((vendedor) => vendedor.value !== ""),
			}));
		});
	};

	const getContactosForDropdown = () => {
		request("contactos?tipo=cliente").then((res) => {
			const contactos = [];
			if (isVendedor()) {
				const rutasGuardadas = JSON.parse(
					localStorage.getItem("rutas")
				);

				for (let { value } of rutasGuardadas) {
					contactos.push(
						...res.data.filter(
							(contacto) => contacto.ruta?.id === value
						)
					);
				}
			}

			setDropdownOptions((options) => ({
				...options,
				contactos: isVendedor()
					? contactos.map((contacto) => ({
							value: contacto.id,
							label: contacto.nombre,
					  }))
					: res.data?.map((contacto) => ({
							value: contacto.id,
							label: contacto.nombre,
					  })),
			}));

            setLoading(false);
		});
	};

	// Informacion de comprobantes
	const getComprobanteData = (comprobante) => {
		if (!comprobante) return;

		request(`facturas/get-comprobante/${comprobante}`).then((res) => {
            setComprobante(res.data);
            setFacturaGuardada(form => ({
                ...form,
                comprobante: res.data,
                numero_comprobante: res.data.prefijo + res.data.numero_siguiente
            }));

			const restantes = res.data.numero_final - res.data.numero_siguiente + 1;
			if (restantes <= 10) {
				toast(`Usted tiene ${restantes} números disponible`, "error");
			}
		});
	};

	const updateTotal = (
		id = null,
		subtotal = null,
		total = 0,
		impuestos = 0,
		descuento = 0,
		impuesto,
        productoInfo = {},
	) => {
        const nproductos = facturaProductos?.map((producto) => {
            if (producto.id === id) {
                producto.producto_id = productoInfo.id;
                producto.importe = subtotal / producto.cantidad
                producto.descuento = descuento;
                producto.subtotal = subtotal;
                producto.impuesto = impuesto;
                producto.impuestos = impuestos;
                producto.total = total;
                if (productoInfo.precio_manual || productoInfo.precio_venta) {
                    producto.precio_facturado = parseFloat(productoInfo.precio_manual ?? productoInfo.precio_venta?.value);
                }
                producto.cantidadAnterior = productoInfo.cantidadAnterior;
            }
            return producto;
        });

		setFacturaProductos(nproductos);

        updateSumatoriaFinal(nproductos, contactoInfo);

        setFacturaGuardada(f => {
            return {
                ...f,
                productos: nproductos.map(p => {
                    const prod = dropdownOptions.productos.find(pr => pr.id === p.producto_id);

                    if (!prod) return p;

                    return {
                        ...p,
                        impuesto_id: p.impuesto?.value,
                        impuesto: {
                            nombre: p.impuesto?.label,
                            id: p.impuesto?.value,
                            porcentaje: p.impuesto?.porcentaje,
                            tipo: p.impuesto?.tipo,
                        },
                        producto: {
                            id: prod.id,
                            nombre: prod.nombre,
                            referencia: prod.referencia,
                            unidad: prod.unidad,
                            tipo: prod.tipo,
                        },
                    }
                }),
            }
        })
	};

	const addProducto = () => {
        setFacturaProductos(prev => [
            ...prev,
            templateProducto(Date.now())
        ])
	};

	const removeProducto = async (index) => {
        const newProds = facturaProductos.filter(
            (i) => i.id !== index
        );
        setFacturaProductos(newProds);
        updateSumatoriaFinal(newProds, contactoInfo);
	};

	const cancel = () => {
        localStorage.removeItem('facturaGuardada');
		history.push(getLink("/ingresos/facturas"));
	};

	const guardarYPagar = () => {
		if(!Object.keys(comprobante).length || !contactoId) {
			toast("Favor completar todos los campos", "error");
			return;
		}
		setShowAgregarPagoModal(true);
	};

	const checkCreditoDisponible = () => {
		if (isVendedor() && isPastDate(facturaActual.fecha)) return true;
		if (
			contactoInfo.credito_disponible_actual <
				sumatoriaFinal.total - form.prevTotal &&
			(plazo.dias > 0 || plazo.dias === null)
		) {
			setShowPINModal(true);
			return false;
		}

		return true;
	};

	const guardarYNuevaFactura = async () => {
		await setCreateButtonClicked("nueva");

		if (!checkCreditoDisponible()) return;

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarEImprimir = async () => {
		await setCreateButtonClicked("imprimir");

		if (!checkCreditoDisponible()) return;

		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const guardarYSalir = async (btnClick) => {
		if ((!pagoInfo.pago || pagoInfo.pago <= 0 || pagoInfo.pago < facturaActual.por_cobrar) && btnClick === "pagar") {
			toast("El monto debe ser mayor o igual al total de la factura.", "error");
			return;
		}

		await setCreateButtonClicked(btnClick);

		if (!checkCreditoDisponible()) {
			return;
		}
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

    const condicionPago = () => {
        if (parseFloat(pagoInfo.pago) < parseFloat(sumatoriaFinal.total) || parseFloat(pagoInfo.pago) <= 0) {
            setPagoInfo((state) => ({ ...state, pago: '' }));
            toast('El monto debe ser mayor o igual al total de la factura.', 'error');
            return false;
        }

        return true;
    }

	const createFactura = async (event) => {
		event.preventDefault();

		setErrors({});

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(data, "facturas", "POST");

		if (response.success) {
			toast("Factura creada", "success");

			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/facturas"));
			} else if (
				createButtonClicked === "pagar" ||
				createButtonClicked === "imprimir"
			) {
				history.push(getLink("/ingresos/facturas"));
				if (tipoImpresionAfterPago === "doble") {
					window.open(
						getLink(
							`/imprimir/facturas/${response.data.factura}/doble`
						)
					);
				} else {
                    window.open(
                        getLink(`/imprimir/facturas/${response.data.factura}`)
                    );
                }
			}
			resetFields();
		}
		if (response.code === 422) {
			setErrors(response.data.message);
			toast("Favor revisar todos los campos", "error");
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

        localStorage.removeItem('facturaGuardada');

		setSaving(false);
	};

	const editFactura = async (event) => {
		event.preventDefault();

        setErrors({});

		setSaving(true);
		const data = new FormData(event.target);
		data.append("_method", "put");

		const response = await apiRequest(
			data,
			`facturas/${facturaActual.id}`,
			"POST"
		);

		if (response.success) {
			toast("Factura actualizada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/ingresos/facturas"));
			} else if (
				createButtonClicked === "pagar" ||
				createButtonClicked === "imprimir"
			) {
				history.push(getLink("/ingresos/facturas"));
				window.open(
					getLink(`/imprimir/facturas/${response.data?.factura}`)
				);
			}
			resetFields();
		}
		if (response.code === 422) {
            setErrors(response.data.message);
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}

        localStorage.removeItem('facturaGuardada');

		setSaving(false);
	};

	const resetFields = () => {
		setCreateButtonClicked("normal");
		setContactoInfo({});
		setForm({
			fecha: dayjs().format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs().format("YYYY-MM-DD"),
			notas: "",
			categorias: [],
		});

        setErrors({});
        setComprobante({id: ''});
        setContactoId(null);
        resetFacturaProductos();
        changePlazo({ value: 0, label: "Seleccione...", dias: 0 });
		setPagoInfo({
			pago: null,
			tipo_pago: "efectivo",
            usarRetencion: false,
		});

		setPinSeguridad("");
		window.scrollTo(0, 0);
	};

    const mostrarPago = (mostrar) => {
        setShowAgregarPagoModal(mostrar);
        setPagoInfo({
			pago: null,
			tipo_pago: "efectivo",
            usarRetencion: false,
		});
    }

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			formRef.current.dispatchEvent(
				new Event("submit", { bubbles: true, cancelable: true })
			);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

	const toggleCancelModal = () => {
		setShowCancel((state) => !state);
	};

	const cancelFactura = async (facturaID) => {
		setShowCancel((state) => !state);
        setErrors({});

		setSaving(true);

		const response = await apiRequest(
			null,
			`facturas/${facturaID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Factura Cancelada", "success");
			history.push(getLink("/ingresos/facturas"));
			return;
		}
		toast("Error cancelando factura", "error");

		setSaving(false);
	};

	const setPorPagar = () => {
		const porPagar = money(
			form.porCobrar - form.prevTotal + sumatoriaFinal.total
		);
		setPagoInfo((state) => ({
			...state,
			pago: porPagar > 0 ? porPagar : 0,
		}));
	};

	const setEditInfo = async () => {
		await setContactoInfo({
			...facturaActual.cliente,
			vendedor: facturaActual.vendedor
				? {
						value: facturaActual.vendedor?.id || facturaActual.vendedor?.value,
						label: facturaActual.vendedor?.nombre ? `${facturaActual.vendedor.nombre} ${facturaActual.vendedor.apellido}` : facturaActual.vendedor?.label,
				  }
				: null,
			ruta: facturaActual.ruta
				? {
						value: facturaActual.ruta?.id || facturaActual.ruta?.value,
						label: facturaActual.ruta?.nombre || facturaActual.ruta?.label,
				  }
				: null,
			credito_disponible_actual: parseFloat(
				facturaActual.cliente?.credito_disponible
			),
		});

        setFacturaProductos(facturaActual.productos);

        setContactoId(facturaActual.cliente?.id);
        changeContactInfo(facturaActual.cliente?.id, false);

        setComprobante(fromPlantilla
            ? { prefijo: null, numero_siguiente: null }
            : {
                    prefijo:
                        facturaActual.numero_comprobante?.substring(0, 3) ||
                        "",
                    id:
                        facturaActual.comprobante?.id,
                    numero_siguiente:
                        facturaActual.numero_comprobante?.substring(
                            3,
                            12
                        ) || "",
              });

        changePlazo({
            value: facturaActual.plazo?.id || facturaActual.plazo?.value,
            label: facturaActual.plazo?.nombre || facturaActual.plazo?.label,
            dias: facturaActual.plazo?.dias,
        });

		setForm((form) => ({
			...form,
			fecha: fromPlantilla
				? dayjs().format("YYYY-MM-DD")
				: dayjs(facturaActual.fecha).format("YYYY-MM-DD"),
			fecha_vencimiento: dayjs(facturaActual.fecha_vencimiento).format(
				"YYYY-MM-DD"
			),
			notas: facturaActual.notas,
			categorias:
				facturaActual.categorias &&
				facturaActual.categorias?.map((c) => ({
					label: c.nombre,
					value: c.id,
				})),
			prevTotal: facturaActual.total || 0,
			porCobrar: facturaActual.porCobrar || 0,
		}));
	};

    const changePlazo = (plazo) => {
        let vencimiento = dayjs(form.fecha);

        setPlazo(plazo);

        if (form.fecha) {
            if (plazo.dias === null) return;

            vencimiento = vencimiento.add(plazo.dias, "day");

            handleInputChange(
                vencimiento.format("YYYY-MM-DD"),
                "fecha_vencimiento"
            );
        }

        setFacturaGuardada(prev => ({
            ...prev,
            fecha_vencimiento: vencimiento.format("YYYY-MM-DD"),
            plazo: plazo,
        }));
    }

    const updateSumatoriaFinal = (productos, contacto) => {
        const total = !isNaN(calcularTotal(productos))
			? calcularTotal(productos)
			: 0;

		setSumatoriaFinal((state) => ({
			...state,
			subtotal: calcularSubtotal(productos),
			descuento: calcularDescuento(productos),
			impuestos: calcularImpuestos(productos),
			exentoGrabado: calcularExentoGrabado(productos),
            total: total,
		}));

		setTotalItbis(calcularTotalImpuestos(productos));

        if (Object.keys(contacto).length >= 0) {
			setContactoInfo((state) => ({
				...state,
				credito_disponible: money(
					state.credito_disponible_actual - (total - form.prevTotal)
				),
			}));
		}
    }

	const isComprobanteFiscal = () => {
		return (
			comprobante?.comprobante_fiscal?.includes("B01") ||
			comprobante?.comprobante_fiscal?.includes("B02") ||
			comprobante?.comprobante_fiscal?.includes("B14") ||
			comprobante?.comprobante_fiscal?.includes("B15") ||
			comprobante?.comprobante_fiscal?.includes("B16")
		);
	};

	useEffect(() => {
		getDropdownData();
		getContactosForDropdown();
	}, []);

	useEffect(() => {
		if (Object.keys(facturaActual).length <= 0) return;
		if (!isEdit && !fromPlantilla && !isRecovery) return;
		setEditInfo();
	}, [facturaActual, facturaActual?.productos]);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<form
				method="post"
				onSubmit={(e) => {
					isEdit ? editFactura(e) : createFactura(e);
				}}
				ref={formRef}
			>
                {!fromPlantilla && (
                    <>
                        <input
                            type="hidden"
                            name="id"
                            value={facturaActual.id}
                        />
                        <input
                            type="hidden"
                            name="recurrencia_id"
                            value={facturaActual.recurrencia_id}
                        />
                    </>
                )}
                {fromPlantilla && (
                    <>
                        {fromOrden === "si" ? (
                            <input
                                type="hidden"
                                name="orden_id"
                                value={facturaActual.id}
                            />
                        ) : (
                            <input
                                type="hidden"
                                name="cotizacion_id"
                                value={facturaActual.id}
                            />
                        )}
                    </>
                )}
				<div className="crear-compra">
					<div className="page-controls">
						<Detalles
							form={form}
							contactoInfo={contactoInfo}
							contactoId={contactoId}
                            setContactoId={setContactoId}
							plazo={plazo}
                            changePlazo={changePlazo}
							dropdownOptions={dropdownOptions}
							toggleCreateContactoModal={
								toggleCreateContactoModal
							}
							toggleEditContactoModal={toggleEditContactoModal}
							handleInputChange={handleInputChange}
							getComprobanteData={getComprobanteData}
							comprobante={comprobante}
							aplicarLimiteFecha={aplicarLimiteFecha}
							handleDetalleChange={handleDetalleChange}
							isComprobanteFiscal={isComprobanteFiscal}
                            changeContactInfo={changeContactInfo}
							isEdit={isEdit}
                            isRecovery={isRecovery}
                            errors={errors}
						/>
					</div>
					<div className="col-md-12 p-0">
						<table className="table d-none d-lg-table">
							<thead>
								<tr>
									<th width="170px">REF./CÓDIGO</th>
									<th width="23%">Producto</th>
									<th width="85px">Cant.</th>
									<th width="85px">Unidad</th>
									<th width="9%">Importe</th>
									<th width="160px">Impuesto</th>
									<th width="180px">Precio</th>
									<th width="78px">% Desc.</th>
									<th className="text-right" width="9%">
										Total
									</th>
									<th className="text-center" width="5%">
										Fotos
									</th>
									<th width="85px"></th>
								</tr>
							</thead>
							<tbody>
								{facturaProductos?.map(
									(producto, index) => (
										<ProductosFactura
											key={producto.id}
											producto={producto}
											index={index}
											removeProducto={removeProducto}
											productos={
												dropdownOptions.productos
											}
											updateTotal={updateTotal}
											grupos={dropdownOptions.grupos}
											precioLimite={
												contactoInfo.precio_venta
											}
											banderaContacto={
												contactoInfo.bandera
											}
											impuestosForDropdown={
												dropdownOptions.impuestos
											}
											facturaProductos={
												facturaProductos
											}
											isEdit={isEdit}
                                            isRecovery={isRecovery}
                                            fromPlantilla={fromPlantilla}
											errors={errors}
											contacto={contactoInfo.id}
										/>
									)
								)}
								<tr>
									<td colSpan="11">
										{errors["productos"] && (
											<small className="help-blockParams form-Text">
												{errors["productos"][0]}
											</small>
										)}
									</td>
								</tr>
								<tr>
									<td
										colSpan="11"
										style={{ border: 0, paddingTop: 0 }}
										className="text-right"
									>
										<button
											type="button"
											onClick={addProducto}
											className="btn"
										>
											<i className="fas fa-plus" />{" "}
											Agregar Producto
										</button>
									</td>
								</tr>
                                {sumatoriaFinal?.descuento > 0 && (
                                    <tr>
                                        <td colSpan="9" className="text-right">
                                            <strong className="label form-control-label">
                                                Descuento:
                                            </strong>
                                        </td>
                                        <td colSpan="2" className="text-right">
                                            - {formatCurrency(
                                                money(sumatoriaFinal?.descuento)
                                            )}
                                        </td>
                                    </tr>
                                )}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Subtotal:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="subtotal"
											value={sumatoriaFinal.subtotal || 0}
											readOnly
										/>
                                        <input
                                            type="hidden"
                                            name="impuestos"
                                            value={totalitbis}
                                            readOnly
                                        />
                                        <input
                                            type="hidden"
                                            name="descuento"
                                            value={
                                                sumatoriaFinal?.descuento || 0
                                            }
                                            readOnly
                                        />
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</td>
								</tr>
                                {sumatoriaFinal?.exentoGrabado?.Exento && Object.keys(sumatoriaFinal.exentoGrabado).filter(k => ['Exento', 'Grabado'].includes(k)).map(
									(impuesto, index) => (
										<tr key={index}>
											<td
												colSpan="9"
												className="text-right"
											>
												<strong className="label form-control-label">
													{impuesto}
												</strong>
											</td>
											<td
												colSpan="2"
												className="text-right"
											>
												{formatCurrency(
													money(
														sumatoriaFinal
															.exentoGrabado[impuesto]
													)
												)}
											</td>
										</tr>
									)
								)}
								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<tr key={index}>
											<td
												colSpan="9"
												className="text-right"
											>
												<strong className="label form-control-label">
													{impuesto}
												</strong>
											</td>
											<td
												colSpan="2"
												className="text-right"
											>
												{formatCurrency(
													money(
														sumatoriaFinal
															.impuestos[impuesto]
													)
												)}
											</td>
										</tr>
									)
								)}
								<tr>
									<td colSpan="9" className="text-right">
										<strong className="label form-control-label">
											Total:
										</strong>
									</td>
									<td colSpan="2" className="text-right">
										<input
											type="hidden"
											name="pagado"
											value="0"
											readOnly
										/>
										<input
											type="hidden"
											name="total"
											value={money(sumatoriaFinal.total)}
											readOnly
										/>
										{formatCurrency(
											money(sumatoriaFinal.total)
										)}
									</td>
								</tr>
                                {errors.total && (
                                    <tr>
                                        <td colSpan="11" className="text-right">
                                            {errors.total && (
                                                <small className="help-blockParams form-Text">
                                                    {errors.total[0]}
                                                </small>
                                            )}
                                        </td>
                                    </tr>
                                )}
							</tbody>
						</table>
						{window.screen.width <= 992 && (
							<div className="d-block d-lg-none">
								{facturaProductos?.map(
										(producto, index) => (
											<ProductosFacturaMobile
												key={producto.id}
												producto={producto}
												index={index}
												removeProducto={removeProducto}
												productos={
													dropdownOptions.productos
												}
												updateTotal={updateTotal}
												precioLimite={
													contactoInfo.precio_venta
												}
												banderaContacto={
													contactoInfo.bandera
												}
												impuestosForDropdown={
													dropdownOptions.impuestos
												}
												facturaProductos={
													facturaProductos
												}
												isEdit={isEdit}
                                                isRecovery={isRecovery}
                                                fromPlantilla={fromPlantilla}
												errors={errors}
												contacto={contactoInfo.id}
											/>
										)
									)}
								{errors.productos && (
									<small className="help-blockParams form-Text">
										{errors.productos[0]}
									</small>
								)}
								<div className="d-flex justify-content-end mb-3">
									<button
										type="button"
										onClick={addProducto}
										className="btn"
										style={{
											border: "1px solid #FE9D1F",
											color: "#6c757d",
										}}
									>
										<i className="fas fa-plus" /> Agregar
										Producto
									</button>
								</div>
								<div className="row px-4 justify-content-between">
									<strong className="label form-control-label">
										Subtotal:
									</strong>
									<span>
										{formatCurrency(
											money(sumatoriaFinal.subtotal)
										)}
									</span>
								</div>

                                {sumatoriaFinal?.exentoGrabado?.Exento && Object.keys(sumatoriaFinal.exentoGrabado).map(
									(impuesto, index) => (
										<div
											className="row px-4 justify-content-between"
											key={index}
										>
											<strong className="label form-control-label">
												{impuesto}
											</strong>

											{formatCurrency(
												money(
													sumatoriaFinal.exentoGrabado[
														impuesto
													]
												)
											)}
										</div>
									)
								)}

								{Object.keys(sumatoriaFinal.impuestos).map(
									(impuesto, index) => (
										<div
											className="row px-4 justify-content-between"
											key={index}
										>
											<strong className="label form-control-label">
												{impuesto}
											</strong>

											{formatCurrency(
												money(
													sumatoriaFinal.impuestos[
														impuesto
													]
												)
											)}
										</div>
									)
								)}

                                <div className="row px-4 justify-content-between">
									<strong className="label form-control-label">
										Descuentos:
									</strong>
									<span>
										-
										{formatCurrency(
											money(sumatoriaFinal.descuento)
										)}
									</span>
								</div>

								<div className="row px-4 justify-content-between">
									<strong className="label form-control-label">
										Total:
									</strong>
									<span>
										{formatCurrency(
											money(sumatoriaFinal.total)
										)}
									</span>
								</div>
							</div>
						)}
						<hr />
					</div>

                    <div className="col-md-12 d-none">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td><small>ITBIS 16%</small></td>
                                    <td><small>ITBIS 18%</small></td>
                                    <td><small>GRABADO ITBIS 16%</small></td>
                                    <td><small>GRABADO ITBIS 18%</small></td>
                                    <td><small>EXENTO</small></td>
                                    <td><small>GRABADO</small></td>
                                    <td><small>IMPUESTO TOTAL</small></td>
                                    <td><small>DESCUENTO</small></td>
                                    <td><small>SUBTOTAL</small></td>
                                    <td><small>TOTAL</small></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {money(sumatoriaFinal?.impuestos['ITBIS 16%'])}
                                        <input type="hidden" name="sumatoria[ITBIS 16%]" value={money(sumatoriaFinal?.impuestos['ITBIS 16%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.impuestos['ITBIS 18%'])}
                                        <input type="hidden" name="sumatoria[ITBIS 18%]" value={money(sumatoriaFinal?.impuestos['ITBIS 18%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['ITBIS 16%'])}
                                        <input type="hidden" name="sumatoria[Grabado ITBIS 16%]" value={money(sumatoriaFinal?.exentoGrabado['ITBIS 16%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['ITBIS 18%'])}
                                        <input type="hidden" name="sumatoria[Grabado ITBIS 18%]" value={money(sumatoriaFinal?.exentoGrabado['ITBIS 18%'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['Exento'])}
                                        <input type="hidden" name="sumatoria[Exento]" value={money(sumatoriaFinal?.exentoGrabado['Exento'])} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal?.exentoGrabado['Grabado'])}
                                        <input type="hidden" name="sumatoria[Grabado]" value={money(sumatoriaFinal?.exentoGrabado['Grabado'])} />
                                    </td>
                                    <td>
                                        {money(totalitbis)}
                                        <input type="hidden" name="sumatoria[Total ITBIS]" value={money(totalitbis)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.descuento)}
                                        <input type="hidden" name="sumatoria[Descuento]" value={money(sumatoriaFinal.descuento)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.subtotal)}
                                        <input type="hidden" name="sumatoria[Subtotal]" value={money(sumatoriaFinal.subtotal)} />
                                    </td>
                                    <td>
                                        {money(sumatoriaFinal.total)}
                                        <input type="hidden" name="sumatoria[Total]" value={money(sumatoriaFinal.total)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

					<div className="col-md-12">
                        <div className="row">
							<div
								className="form-group col-md-6"
								style={{ marginBottom: "0.3rem" }}
							>
								{facturaActual.facturaFirmada?.length > 0 ? (
												<label className="label form-control-label">
													Factura Firmada en PDF:
												</label>
											) : (
												<label className="label form-control-label">
													Adjuntar Factura Firmada:
												</label>
											)}
											<FileLoader
												name="factura_firmada[]"
												archivos={facturaActual.facturaFirmada ?? []}
											/>
											{errors.facturaFirmada && (
												<small className="help-blockParams form-Text">
													{errors.facturaFirmada[0]}
												</small>
											)}
							</div>
							<div
								className="form-group col-md-6"
								style={{ marginBottom: "0.3rem" }}
							>
								<label className="label form-control-label">
									notas:
								</label>
								<textarea
									className="text-input form-control"
									id="notas"
									name="notas"
									rows={4}
									value={form.notas}
                                    onChange={e => handleInputChange(e.target.value, 'notas')}
								/>
								{errors.notas && (
									<small className="help-blockParams form-Text">
										{errors.notas[0]}
									</small>
								)}
							</div>
						</div>
					</div>
					{/* DESKTOP FOOTER */}
					<div className="page-footer d-none d-lg-block text-right">
						<button
							type="button"
							onClick={cancel}
							className="btn btn-plain"
						>
							Descartar
						</button>
						{isEdit && (
							<>
								{!saving && (
									<button
										type="button"
										onClick={() => toggleCancelModal()}
										className="btn btn-outline-danger mr-2"
									>
										Cancelar Factura
									</button>
								)}
							</>
						)}

						{canSaveAndPay(isEdit, saving, form.fecha, (form.porCobrar !== form.prevTotal)) && (
							<button
								type="button"
								onClick={() => guardarYPagar()}
								className="btn btn-outline-primary mr-2"
							>
								Guardar y agregar pago
							</button>
						)}

						{!isEdit && !saving && (
							<>
								<button
									type="button"
									onClick={() => guardarYNuevaFactura()}
									className="btn btn-outline-primary mr-2"
								>
									Guardar y crear nueva
								</button>
							</>
						)}
						{!saving && (
							<>
								<button
									type="button"
									onClick={() => guardarEImprimir()}
									className="btn btn-outline-primary mr-2"
								>
									Guardar e imprimir
								</button>
							</>
						)}
						{saving ? (
							<Loader type="small" />
						) : (
							<button
								type="button"
								onClick={() => guardarYSalir("normal")}
								className="btn btn-primary"
							>
								Guardar
							</button>
						)}
					</div>
					{/* MOBILE FOOTER */}
					<div className="page-footer d-block d-lg-none text-right">
						<div className="row justify-content-center mb-2">
							{saving ? (
								<Loader type="small" />
							) : (
								<button
									type="button"
									onClick={() => guardarEImprimir()}
									className="btn btn-primary col-11 ml-0"
								>
									Guardar e imprimir
								</button>
							)}
						</div>

						<div className="row justify-content-around">
							{!saving && (
								<>
									<button
										type="button"
										onClick={() => guardarYSalir("normal")}
										className="btn btn-outline-primary col-11 col-sm-5 mb-2 ml-0"
									>
										Guardar
									</button>
								</>
							)}
							{isEdit && (
								<>
									{!saving && (
										<button
											type="button"
											onClick={() => toggleCancelModal()}
											className="btn btn-outline-danger col-11 col-sm-5 ml-0 mb-2"
										>
											Cancelar Factura
										</button>
									)}
								</>
							)}
							{!isEdit && !saving && (
								<>
									<button
										type="button"
										onClick={() => guardarYNuevaFactura()}
										className="btn btn-outline-primary col-11 col-sm-5 mb-2 ml-0"
									>
										Guardar y crear nueva
									</button>
								</>
							)}
						</div>
						<div className="row justify-content-around">
							{canSaveAndPay(isEdit, saving, form.fecha) && (
								<button
									type="button"
									onClick={() => guardarYPagar()}
									className="btn btn-outline-primary col-11 col-sm-5 ml-0"
								>
									Guardar y agregar pago
								</button>
							)}
							<button
								type="button"
								onClick={cancel}
								className="btn btn-plain col-11 col-sm-5 ml-0"
							>
								Descartar
							</button>
						</div>
					</div>
					{/* <---PIN DE SEGURIDAD MODAL--- */}
					<Modal
						title="PIN de seguridad"
						show={showPINModal}
						callback={() => checkPINSeguridad()}
						acceptText="Aceptar"
						toggle={() => setShowPINModal((state) => !state)}
						size="419px"
					>
						<div className="form-group">
							<label>PIN de seguridad</label>
							<input
								type="password"
								className="form-control"
								id="PIN"
								name="pin_seguridad"
								value={pinSeguridad}
								onChange={(e) =>
									setPinSeguridad(e.target.value)
								}
							/>
							<p
								className="font-weight-bold"
								style={{
									color: "gray",
									fontStyle: "italic",
									lineHeight: "13px",
									fontSize: "0.7rem",
								}}
							>
								El cliente no cuenta con credito suficiente.
								Para continuar ingrese PIN de seguridad
							</p>
						</div>
					</Modal>
					{/* ---AGREGAR PAGO MODAL--- */}
					<Modal
						title="Agregar Pago"
						show={showAgregarPagoModal}
						callback={() => {
							// if (!pagoInfo.pago || pagoInfo.pago <= 0) {
							// 	toast("Agregue el monto a pagar", "error");
							// 	return;
							// }
							setShowAgregarPagoModal(false);
							guardarYSalir("pagar");
						}}
						acceptText="Aceptar"
						toggle={() => {
							setPagoInfo({ pago: 0, tipo_pago: "efectivo", usarRetencion: false });
							setShowAgregarPagoModal(false);
						}}
						size="419px"
						footer={
							<PagoModalFooter
								setMostrar={mostrarPago}
								setTipoImpresionAfterPago={
									setTipoImpresionAfterPago
								}
								guardarYSalir={guardarYSalir}
								saving={saving}
							/>
						}
					>
						<PagoForm
                            pagoInfo={pagoInfo}
                            setPagoInfo={setPagoInfo}
                            isEdit={isEdit}
                            setPorPagar={setPorPagar}
                            form={form}
                            sumatoriaFinal={sumatoriaFinal}
                            condicionPago={condicionPago}
                        />
					</Modal>
					<input
						type="hidden"
						min="0"
						className="form-control"
						name="pago"
						value={
							pagoInfo.pago > sumatoriaFinal.total
								? sumatoriaFinal.total || 0
								: pagoInfo.pago || 0
						}
						onChange={() => {}}
					/>
				</div>
				<input
					type="hidden"
					min="0"
					className="form-control"
					name="tipo_pago"
					value={pagoInfo.tipo_pago}
					onChange={() => {}}
				/>
				<input
					type="hidden"
					name="metodo_pago[terminal_tarjeta]"
					value={pagoInfo.terminal_tarjeta}
				/>
				<input
					type="hidden"
					name="metodo_pago[tipo]"
					value={pagoInfo.tipo}
				/>
				<input
					type="hidden"
					name="metodo_pago[banco]"
					value={pagoInfo.banco}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_aprobacion]"
					value={pagoInfo.num_aprobacion}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_cheque]"
					value={pagoInfo.num_cheque}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_transferencia]"
					value={pagoInfo.num_transferencia}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_deposito]"
					value={pagoInfo.num_deposito}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_transaccion]"
					value={pagoInfo.num_transaccion}
				/>
			</form>
			{/* ---CREAR CONTACTO MODAL--- */}
			<Modal
				show={showCreateContactoModal}
				blank={true}
				toggle={toggleCreateContactoModal}
				size="lg"
			>
				<Create toggle={toggleCreateContactoModal} fromFacturacion />
			</Modal>
			{/* ---EDITAR CONTACTO MODAL--- */}
			<Modal
				show={showEditContactoModal}
				blank={true}
				toggle={toggleEditContactoModal}
				size="lg"
			>
				<Edit
					toggle={toggleEditContactoModal}
					contacto={contactoInfo}
					fromFacturacion
				/>
			</Modal>
			{/* ---CONFIRMACIÓN CANCELAR FACTURA--- */}
			<Modal
				title="Cancelar Factura"
				show={showCancel}
				callback={() => cancelFactura(facturaActual.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar esta factura? (Esta acción
				no se puede deshacer).
			</Modal>
		</>
	);
};

export default Form;
