import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import dayjs from "dayjs";
import {
	Switch,
	Route,
	Link,
	useRouteMatch,
	useHistory,
} from "react-router-dom";
import Select from "react-select";

import {
	getLink,
	formatCurrency,
	toast,
	getZonasForDropdown,
	getVendedoresForDropdown,
	getCategoriasForDropdown,
	isVendedor,
	validarPINSeguridad,
	isPastDate,
	bancos,
    getOptionsForDropdown,
    formatNumber,
	getPlazosForDropdown,
} from "../../../utils/helpers";
import CrearFactura from "./Create";
import PageHeader from "../../general/PageHeader";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import Modal from "../../general/Modal";
import { downloadFile, request } from "../../../utils/request";
import { apiRequest } from "../../../hooks/apiRequest";
import EditFactura from "./Edit";
import PrefixedInput from "../../general/PrefixedInput";
import FiltersContainer from "../../general/FiltersContainer";
import useFilters from "../../../hooks/useFilters";
import { auth } from "../../../utils/auth";
import InvalidScreen from "../../general/InvalidScreen";
import Dropdown from "../../general/Dropdown";
import { ESTADOS_TRANSACCION, METODOS_PAGO } from "../../../utils/types";

const formatDateTime = (date) => {
	return dayjs(date).format("DD/MM/YYYY hh:mm a");
};

const renderEstadoBadge = (estado, factura) => {
	if (estado === "pendiente") {
		return (
			<span
				className="badge badge-secondary p-2"
				// style={{ cursor: "pointer" }}
				// onDoubleClick={() => toggleEstado(factura)}
			>
				Pendiente
			</span>
		);
	} else if (estado === "pagada") {
		return (
			<span
				className="badge badge-success p-2"
				// style={{ cursor: "pointer" }}
				// onDoubleClick={() => toggleEstado(factura)}
			>
				Pagada
			</span>
		);
	}

	return <span className="badge badge-danger p-2">Cancelada</span>;
};

const canValidate = (factura) =>
	factura.recurrencia_id &&
	factura.validada === "no" &&
	factura.estado === "pendiente";

const canPay = (factura) => {
    return (factura.por_cobrar === factura.total) && (factura.estado === "pendiente" &&
	dayjs().startOf("day") < dayjs(factura.fecha_emision).endOf("day"));
}

const canPrint = (factura) =>
	!!factura?.tipo_pago &&
	factura?.tipo_pago !== "efectivo" &&
	factura.estado !== "pagada";

const Facturas = () => {
	// Route  matcher
	let match = useRouteMatch();
	const history = useHistory();
	const formRef = useRef();
	const formInfoPagoRef = useRef();

	const { filters, handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		vendedor:
			auth.is("vendedor-factura") ||
			auth.is("vendedor-factura-orden_compra")
				? auth.getUser().empleado?.id
				: "",
		estado: "",
		metodo_pago: "",
		desde: dayjs().format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
		super_filter: "",
	});

	const [facturas, setFacturas] = useState({});
	const [vendedores, setVendedores] = useState([]);
	const [empleados, setEmpleados] = useState([]);
	const [zonas, setZonas] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const [plazos, setPlazos] = useState([]);
	const [numeraciones, setNumeraciones] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showAgregarPagoModal, setShowAgregarPagoModal] = useState(false);
	const [showValidateModal, setShowValidateModal] = useState(false);
	const [showAgregarInfoPagoModal, setShowAgregarInfoPagoModal] =
		useState(false);
	const [printFacturaTipo, setPrintFacturaTipo] = useState("normal");
	const [facturaSeleccionada, setFacturaSeleccionada] = useState({});
	const [pagoError, setPagoError] = useState({});
	const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
	});
	const [pinSeguridad, setPinSeguridad] = useState("");
	const [showPINModal, setShowPINModal] = useState(false);
	const [tipoImpresionAfterPago, setTipoImpresionAfterPago] = useState("normal");

	const [showModalRutaDistribucion, setShowModalRutaDistribucion] = useState(false);
	const [desdeRD, setDesdeRD] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
	const [hastaRD, setHastaRD] = useState(dayjs().endOf("month").format("YYYY-MM-DD"));
	const [empleadoRD, setEmpleadoRD] = useState();

	const getFacturas = (url) => {
		url = url ? url + "&limit=15" : "facturas?limit=15";
		setIsLoading(true);
		// setTempUrl(url);

		request(url).then((res) => {
			setFacturas(res);
			setIsLoading(false);
		});
	};

	const onFilterChange = debounce(async (value, filtro) => {
		// if (filtro === "buscar") {
		// 	handleFilterChange(
		// 		"hasta",
		// 		dayjs().add(2, "year").format("YYYY-MM-DD")
		// 	);
		// 	handleFilterChange(
		// 		"desde",
		// 		dayjs().subtract(2, "year").format("YYYY-MM-DD")
		// 	);
		// }
		await handleFilterChange(
			filtro,
			value instanceof Array ? value.map((v) => v.value).join(",") : value
		);
	}, 500);

	// const toggleEstado = async (factura) => {
	// 	const response = await apiRequest(
	// 		null,
	// 		`facturas/${factura}/update-estado`,
	// 		"put"
	// 	);

	// 	if (response.success) {
	// 		getFacturas(tempUrl);
	// 		toast("Estado actualizado");
	// 		return;
	// 	}

	// 	toast("Error Actualizando estado", "error");
	// };
    const [retencion, setRetencion] = useState(0);
    const [usarRetencion, setUsarRetencion] = useState(false);

	const openPagarFacturaModal = (factura) => {
		setFacturaSeleccionada(factura);
		setPagoInfo((state) => ({ tipo_pago: factura.tipo_pago }));
		setShowAgregarPagoModal(true);
        setRetencion(factura.impuestos * 0.3);
	};

	const openValidarFacturaModal = (factura) => {
		setFacturaSeleccionada(factura);
		setShowValidateModal(true);
	};

	const validateFactura = async (facturaId) => {
		const response = await apiRequest(
			null,
			`facturas/${facturaId}/validate`,
			"PUT"
		);

		if (response.success) {
			getFacturas(`facturas${filtersQuery}`);
			setShowValidateModal(false);
			toast("Factura validada");
			return;
		}

		toast("Error Validando Factura", "error");
	};

	const openInfoPagoModal = (factura, tipo) => {
		setFacturaSeleccionada(factura);
		setPrintFacturaTipo(tipo);
		setShowAgregarInfoPagoModal(true);
        setRetencion(factura.impuestos * 0.3);
	};

	const guardarPago = async (event) => {
		event.preventDefault();

		if (!pagoInfo.pago || pagoInfo.pago <= 0 || pagoInfo.pago < facturaSeleccionada.por_cobrar) {
			toast("El monto debe ser mayor o igual al total de la factura.", "error");
			return;
		}

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			toast("Pago Agregado", "success");
			setShowAgregarPagoModal(false);
			setFacturaSeleccionada({});
            setUsarRetencion(false);
			setPagoInfo({ pago: null, tipo_pago: "efectivo" });
			getFacturas(`facturas${filtersQuery}`);
			if (tipoImpresionAfterPago === "doble") {
				window.open(
					getLink(
						`/imprimir/facturas/${facturaSeleccionada.id}/doble`
					)
				);
				return;
			}
			window.open(
				getLink(`/imprimir/facturas/${facturaSeleccionada.id}`)
			);
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	const guardarInfoMetodoPago = async (event) => {
		event.preventDefault();

		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`agregar-info-metodo-pago-factura/${facturaSeleccionada.id}`,
			"POST"
		);

		if (response.success) {
			if (printFacturaTipo === "normal") {
				window.open(
					getLink(`/imprimir/facturas/${facturaSeleccionada.id}`)
				);
			} else {
				window.open(
					getLink(
						`/imprimir/facturas/${facturaSeleccionada.id}/doble`
					)
				);
			}
			setShowAgregarInfoPagoModal(false);
			setFacturaSeleccionada({});
			// setInfoMetodoPago({});
			return;
		} else if (response.code === 422) {
			setPagoError(response.data.message);
			return;
		}

		toast(response.data.message, "error");
	};

	const setPorPagar = () => {
		setPagoInfo((state) => ({
			...state,
			pago: facturaSeleccionada.por_cobrar,
		}));
	};

	const openPINModal = (factura) => {
		setFacturaSeleccionada(factura);
		setShowPINModal(true);
	};

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			history.push(
				getLink(`/ingresos/facturas/${facturaSeleccionada.id}/edit`)
			);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

    const displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    };

	const openModalRutaDistribucion = () => {
		setShowModalRutaDistribucion(true);
	};

	const downloadRutaDistribucionReporte = async () => {
		if (!desdeRD || !hastaRD || !empleadoRD) {
			toast("Ingrese desde, hasta y Empleado.", 'error');
			return;
		}

		await downloadFile(`reportes/ruta-distribucion${filtersQuery}&desde=${desdeRD}&hasta=${hastaRD}&empleadoId=${empleadoRD.value}&empleadoName=${empleadoRD.label}`, 'ruta_distribucion.xls');

		setShowModalRutaDistribucion(false);
	};

	const getEmpleadosForDropdown = async () => {
		const response = await request("empleados?limit=1000");
		const filteredResponse = response.data.filter(empleado => empleado !== null);

		setEmpleados([
			...filteredResponse.map((empleado) => ({
			value: empleado?.id || null,
			label: `${empleado?.nombre} ${empleado?.apellido}`,
			}))
		]);
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getFacturas(`facturas${filtersQuery}`);
	}, [filtersQuery]);

	useEffect(() => {
		getCategoriasForDropdown().then((data) => {
			setCategorias(data);
		});
		getPlazosForDropdown().then((data) => {
			setPlazos(data);
		});
		getOptionsForDropdown('numeracion-documentos?tipo_documento=factura_venta').then((data) => {
			setNumeraciones(data);
		});
		getEmpleadosForDropdown();
		if (isVendedor()) {
			const rutas = JSON.parse(localStorage.getItem("rutas"));
			setZonas(rutas);
		} else {
			getZonasForDropdown().then((data) => {
				setZonas(data);
			});
			getVendedoresForDropdown().then((data) => {
				setVendedores(data);
			});
		}
	}, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

    const user = auth.getUser();
    const sucursalActual = user.sucursales.filter(s => s.id === user.sucursalActual)[0];

	return (
		<>
			<PageHeader title="Facturas">
				<div className="col-12 col-lg-3  pr-md-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Consulta
					</label>
					<input
						onChange={(e) =>
							onFilterChange(e.target.value, "super_filter")
						}
						type="search"
						placeholder="Consultar..."
						className="form-control"
						name="super_filter"
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2 pr-md-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						value={filters.desde}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "desde")
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						value={filters.hasta}
						className="form-control"
						onChange={(e) =>
							onFilterChange(e.target.value, "hasta")
						}
					/>
				</div>
				<div className="dropdown m-r-10 d-none d-lg-inline-block">
					<button className="au-btn au-btn-icon au-btn-filter">
						<i className="zmdi zmdi-print"></i> Reportes
					</button>
					<ul
						style={{ right: "0", left: "auto" }}
						className="dropdown-menu"
					>
						<li>
							<Link
								to={getLink(
									`/imprimir/facturas${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Reporte Facturas
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/facturas-resumen${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Resumen Facturas
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/facturas-detalles${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Detalle de Impuestos
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/facturas-detalles-2${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Detalle de Impuestos 2.0
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/cuadre-diario${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Cuadre de Caja
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/cuadre-diario-resumido${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Cuadre de Caja Resumido
							</Link>
						</li>
						<li>
							<Link
								to={getLink(
									`/imprimir/lista-carga${filtersQuery}`
								)}
								target="_blank"
								className="dropdown-item"
							>
								Lista de carga
							</Link>
						</li>
						<li>
							<Link
								to={getLink(`/imprimir/balance${filtersQuery}`)}
								target="_blank"
								className="dropdown-item"
							>
								Balance
							</Link>
						</li>
						<li>
							<button
								onClick={openModalRutaDistribucion}
								target="_blank"
								className="dropdown-item"
							>
								Ruta de distribución
							</button>
						</li>
					</ul>
				</div>
				<Link
					className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15 ml-auto mt-2 mt-lg-0"
					to={`${match.url}/crear`}
				>
					<i className="zmdi zmdi-plus" />
					Añadir Factura
				</Link>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label className="orden-filter-label">Buscar</label>
					<input
						onChange={(e) =>
							onFilterChange(e.target.value, "buscar")
						}
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label">Tipo de pago</label>
					<Select
						id="metodo_pago"
						options={[
							{ value: "", label: "Todos" },
							...METODOS_PAGO,
						]}
						name="metodo_pago"
						onChange={(e) => onFilterChange(e.value, "metodo_pago")}
						placeholder="Todos"
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label">Estado</label>
					<Select
						id="estado"
						options={[
							{ value: "", label: "Todos" },
							...ESTADOS_TRANSACCION,
						]}
						name="estado"
						isMulti
						isClearable
						onChange={(values) => onFilterChange(values, "estado")}
						placeholder="Todos"
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label className="orden-filter-label">Zona</label>
					<Select
						id="zona"
						options={zonas}
						name="zona"
						onChange={(values) => onFilterChange(values, "zona")}
						placeholder="Todos"
						isClearable
						isMulti
					/>
				</div>
				{!isVendedor() && (sucursalActual.tipo !== 'educativa') && (
					<div className="col-12 col-md-6 col-lg-2">
						<label className="orden-filter-label">Vendedor</label>
						<Select
							id="vendedor"
							options={vendedores}
							name="vendedor"
							placeholder="Todos"
							onChange={(e) =>
								onFilterChange(e.value, "vendedor")
							}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
						/>
					</div>
				)}
                <div className="col-12 col-md-6 col-lg-2">
                    <label className="orden-filter-label">Categoría</label>
                    <Select
                        id="categoria"
                        options={categorias}
                        name="categoria"
                        isMulti
                        isClearable
                        placeholder="Todas"
                        onChange={(values) =>
                            onFilterChange(values, "categoria")
                        }
                    />
                </div>
                <div style={{width: '50px'}} className="filtros-avanzados" onClick={displayFilters}>
                    <label>&nbsp;</label>
                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                </div>
                <div id="mas-filtros" className="row col-md-12 mas-filtros d-none m-b-25 m-t-5">
                    <div className="col-12 col-md-6 col-lg-3">
                        <label className="orden-filter-label">Numeración</label>
                        <Select
                            id="numeracion"
                            options={numeraciones}
                            name="numeracion"
                            isMulti
                            isClearable
                            placeholder="Todas"
                            onChange={(values) =>
                                onFilterChange(values, "numeracion")
                            }
                        />
                    </div>

					<div className="col-12 col-md-6 col-lg-3">
                        <label className="orden-filter-label">Plazo</label>
						<select
							className="form-control"
							name="plazo"
							id="plazo"
							onChange={(e) =>
								onFilterChange(
									e.target.value,
									"plazo"
								)
							}
						>
							<option value="todos">Todos</option>
							{plazos.map((termino) => (
								<option
									value={termino.value}
									key={termino.value}
								>
									{termino.label}
								</option>
							))}
						</select>
                    </div>
                </div>
			</FiltersContainer>


			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && facturas.data?.length ? (
					<>
						<FacturasListDesktop
							facturas={facturas}
							openValidarFacturaModal={openValidarFacturaModal}
							openPagarFacturaModal={openPagarFacturaModal}
							openInfoPagoModal={openInfoPagoModal}
							openPINModal={openPINModal}
						/>

						<FacturasListMobile
							facturas={facturas}
							openValidarFacturaModal={openValidarFacturaModal}
							openPagarFacturaModal={openPagarFacturaModal}
							openInfoPagoModal={openInfoPagoModal}
							openPINModal={openPINModal}
						/>
					</>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ninguna Factura creada.</div>
						)}
					</div>
				)}
				{facturas.links && (
					<Pagination
						links={facturas.links}
						meta={facturas.meta}
						getData={getFacturas}
					/>
				)}
			</div>
			{/* AGREGAR PAGO MODAL */}
			<Modal
				title="Agregar Pago"
				show={showAgregarPagoModal}
				callback={() => {
					if (!pagoInfo.pago || pagoInfo.pago <= 0 || pagoInfo.pago < facturaSeleccionada.por_cobrar) {
						toast("El monto debe ser mayor o igual al total de la factura.", "error");
						return;
					}
					formRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					setPagoInfo({ pago: null, tipo_pago: "efectivo" });
					setShowAgregarPagoModal((state) => !state);
				}}
				size="419px"
				footer={
					<PagoModalFooter
						setMostrar={(value) => {
                            setShowAgregarPagoModal(value);
                            setUsarRetencion(value);
                        }}
						setTipoImpresionAfterPago={setTipoImpresionAfterPago}
						formRef={formRef}
						saving={false}
					/>
				}
			>
				<form method="post" onSubmit={guardarPago} ref={formRef}>
					<div className="form-group">
						<label>Tipo de pago</label>
						<select
							className="form-control"
							name="tipo_pago"
							value={pagoInfo.tipo_pago}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									terminal_tarjeta: "",
									tipo: "visa",
									banco: "",
									num_aprobacion: "",
									num_cheque: "",
									num_transferencia: "",
									num_transaccion: "",
									num_deposito: "",
									tipo_pago: e.target.value,
								}));
							}}
						>
							{METODOS_PAGO.map((metodo) => (
								<option value={metodo.value}>
									{metodo.label}
								</option>
							))}
						</select>
					</div>

					{pagoInfo.tipo_pago === "cheque" && (
						<ChequeInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "tarjeta" && (
						<TarjetaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "transferencia" && (
						<TransferenciaInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}
					{pagoInfo.tipo_pago === "deposito" && (
						<DepositoInfo factura={facturaSeleccionada} />
					)}
					{pagoInfo.tipo_pago === "otro" && (
						<OtroInfo
							pagoInfo={pagoInfo}
							setPagoInfo={setPagoInfo}
							factura={facturaSeleccionada}
						/>
					)}

					<div className="form-group">
						<label>Monto Recibido</label>
						<PrefixedInput
							type="number"
							prefix="RD$"
							name="monto"
							value={pagoInfo.pago}
							setValue={(e) => {
                                setPagoInfo((state) => ({ ...state, pago: e }))
                            }}
                            onBlur={e => {
                                if (e < facturaSeleccionada.por_cobrar) {
                                    setPagoInfo((state) => ({ ...state, pago: '' }))
                                    toast('El monto debe ser mayor o igual al total de la factura.', 'error');
                                    return;
                                }
                            }}
						/>
						<input
							type="hidden"
							min="0"
							className="form-control"
							defaultValue={formatCurrency(pagoInfo.pago)}
							onChange={(e) => {
								e.persist();
								setPagoInfo((state) => ({
									...state,
									pago: e.target.value,
								}));
							}}
						/>
						<input
							type="hidden"
							name="monto"
							value={pagoInfo.pago}
						/>
						{pagoError.monto && (
							<small className="help-blockParams form-Text">
								{pagoError.monto[0]}
							</small>
						)}
					</div>
					<div
						className="row"
						style={{ justifyContent: "space-around" }}
					>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
								cursor: "pointer",
							}}
							onClick={() => setPorPagar()}
						>
							<h5 className="mb-2">Balance:</h5>
							<p>
								{formatCurrency(facturaSeleccionada.por_cobrar)}
							</p>
						</div>
						<div
							className="col-5 text-center"
							style={{
								border: "1px solid #cdd2d7",
								borderRadius: "7px",
								paddingTop: "8px",
								paddingBottom: "8px",
							}}
						>
							<h5 className="mb-2">Devuelta:</h5>
							<p>
								{pagoInfo.pago >
								parseFloat(facturaSeleccionada.por_cobrar)
									? formatCurrency(
											pagoInfo.pago -
												facturaSeleccionada.por_cobrar
									  )
									: 0}
							</p>
						</div>

                        <div className="form-group m-t-15 col">
                            <label><input type="checkbox" className='m-r-10' onChange={() => {setUsarRetencion(!usarRetencion)}} />Retención</label>
                            {usarRetencion && (
                                <PrefixedInput
                                    type="number"
                                    prefix="RD$"
                                    name="detalle_metodo_pago[retencion]"
                                    value={formatNumber(retencion)}
                                    setValue={(e) => setRetencion(e)}
                                />
                            )}
                        </div>
					</div>
				</form>
			</Modal>

			{/* VALIDAR FACTURA RECURRENTE MODAL */}
			<Modal
				title="Validar Factura Recurrente"
				show={showValidateModal}
				callback={() => validateFactura(facturaSeleccionada.id)}
				acceptText="Validar"
				toggle={() => {
					setShowValidateModal((state) => !state);
				}}
			>
				¿Estás seguro de que deseas validar esta Factura?
			</Modal>

			{/* AGREGAR INFORMACIÓN DE PAGO MODAL */}
			<Modal
				title="Agregar Información de pago"
				show={showAgregarInfoPagoModal}
				callback={() => {
					formInfoPagoRef.current.dispatchEvent(
						new Event("submit", { bubbles: true, cancelable: true })
					);
				}}
				acceptText="Aceptar"
				toggle={() => {
					// setInfoMetodoPago({});
					setShowAgregarInfoPagoModal((state) => !state);
				}}
				size="419px"
			>
				<form
					method="post"
					onSubmit={guardarInfoMetodoPago}
					ref={formInfoPagoRef}
				>
					{facturaSeleccionada.tipo_pago === "cheque" && (
						<ChequeInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "tarjeta" && (
						<TarjetaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "transferencia" && (
						<TransferenciaInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "deposito" && (
						<DepositoInfo factura={facturaSeleccionada} />
					)}
					{facturaSeleccionada.tipo_pago === "otro" && (
						<OtroInfo factura={facturaSeleccionada} />
					)}
				</form>
			</Modal>

			{/* <---PIN DE SEGURIDAD MODAL--- */}
			<Modal
				title="PIN de seguridad"
				show={showPINModal}
				callback={() => checkPINSeguridad()}
				acceptText="Aceptar"
				toggle={() => setShowPINModal((state) => !state)}
				size="419px"
			>
				<div className="form-group">
					<label>PIN de seguridad</label>
					<input
						type="password"
						className="form-control"
						id="PIN"
						name="pin_seguridad"
						value={pinSeguridad}
						onChange={(e) => setPinSeguridad(e.target.value)}
					/>
					<p
						className="font-weight-bold"
						style={{
							color: "gray",
							fontStyle: "italic",
							lineHeight: "13px",
							fontSize: "0.7rem",
						}}
					>
						Para continuar ingrese PIN de seguridad
					</p>
				</div>
			</Modal>

			{/* Ruta de distribución */}
			<Modal
				title="GENERAR REPORTE RUTA DE DISTRIBUCIÓN"
				show={showModalRutaDistribucion}
				callback={downloadRutaDistribucionReporte}
				acceptText="Generar"
				toggle={() => setShowModalRutaDistribucion((state) => !state)}
				size="650px"
				classes={{
					content: "",
					header: '',
					body: 'overflow-visible',
					footer: ''
				}}
			>
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label>Desde:</label>
							<input
								type='date'
								name='desde'
								id='desde'
								value={desdeRD}
								onChange={(e) => setDesdeRD(e.target.value)}
								className='form-control'
							/>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='form-group'>
							<label>Hasta:</label>
							<input
								type='date'
								name='hasta'
								value={hastaRD}
								onChange={(e) => setHastaRD(e.target.value)}
								id='hasta'
								className='form-control'
							/>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-12'>
						<div className='form-group'>
							<label>Empleado:</label>
							<Select
								id="empleado"
								options={empleados}
								name="empleado"
								onChange={(e) => setEmpleadoRD(e)}
								classNamePrefix="select-menu-z-999"
								styles={{
									control: (styles) => ({
										...styles,
										height: "38px",
									}),
									menu: (styles) => ({
										...styles,
										"z-index": "999",
									}),
								}}
							/>
						</div>
					</div>
				</div>
			</Modal>

			{/* CREAR FACTURA */}
			<Switch>
				<Route path={`${match.url}/crear`}>
					<CrearFactura />
				</Route>
				<Route path={`${match.url}/:id/edit`}>
					<EditFactura />
				</Route>
			</Switch>
		</>
	);
};

const FacturasListDesktop = ({
	facturas,
	openValidarFacturaModal,
	openPagarFacturaModal,
	openInfoPagoModal,
	openPINModal,
}) => {
	return (
		<div
			className="table-data table-responsive table-row__desktop"
			style={{ overflowX: "visible" }}
		>
			<table className="table table-data2">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Cliente</th>
						<th>Fecha</th>
						<th>Vencimiento</th>
						<th>Total</th>
						{/* <th>Balance</th> */}
						{!isVendedor() && <th>Vendedor</th>}
						<th>Zona</th>
						<th className="text-center">Estado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{facturas.data.map((factura) => {
						return (
							<tr className="tr-shadow" key={factura.id}>
								{factura.recurrencia_id &&
								factura.validada === "no" ? (
									<td></td>
								) : (
									<td>{factura.numero_comprobante}</td>
								)}

								<td>
									<Link
                                        to={getLink(`/contactos/${factura.cliente?.id}/transacciones`)}
                                        className="ml-2 contacto"
                                        onClick={() => {localStorage.setItem("contacto", JSON.stringify(factura.cliente));}}
                                    >
                                        {factura.cliente?.nombre}
                                    </Link>
								</td>
								<td>
									<div>
										{dayjs(factura.fecha).format(
											"DD/MM/YYYY"
										)}
									</div>
									<div
										className={`${
											!!factura.recurrencia_id &&
											factura.validada === "si"
												? "d-block"
												: "d-none"
										}`}
									>
										<span className="fw-600">
											Emisión:{" "}
										</span>
										{dayjs(factura.fecha_emision).format(
											"DD/MM/YYYY"
										)}
									</div>
								</td>
								<td>
									{dayjs(factura.fecha_vencimiento).format(
										"DD/MM/YYYY"
									)}
								</td>
								<td>{formatCurrency(factura.total, "$")}</td>
								{/* <td>
									{formatCurrency(factura.por_cobrar, "$")}
								</td> */}
								{!isVendedor() && (
									<>
										{factura.vendedor ? (
											<td>
												{`${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`}
											</td>
										) : (
											<td></td>
										)}
									</>
								)}

								<td>{factura.ruta?.nombre}</td>
								<td className="text-center">
									{renderEstadoBadge(
										factura.estado,
										factura.id
									)}
									<span
										className={`text-success ${
											factura.estado === "pendiente" &&
											factura.recurrencia_id &&
											factura.validada === "si"
												? "d-block"
												: "d-none"
										}`}
									>
										Validada
									</span>
								</td>
								<td>
									<Dropdown
										styles={{
											dropdownMenu: {
												left: "-30%",
											},
										}}
									>
										{canValidate(factura) ? (
											<button
												className="dropdown-item"
												onClick={() =>
													openValidarFacturaModal(
														factura
													)
												}
											>
												Validar
											</button>
										) : (
											<>
												{canPay(factura) && (
													<button
														className="dropdown-item"
														onClick={() =>
															openPagarFacturaModal(
																factura
															)
														}
													>
														Pagar
													</button>
												)}
												{/* {canPrint(factura) ? (
													<button
														type="button"
														className="dropdown-item"
														title="Impresión normal"
														onClick={() =>
															openInfoPagoModal(
																factura,
																"normal"
															)
														}
													>
														Impresión
													</button>
												) : (
												)} */}
                                                <Link
                                                    to={getLink(
                                                        `/imprimir/facturas/${factura.id}?original=1`
                                                    )}
                                                    target="_blank"
                                                    className="dropdown-item"
                                                    title="Impresión normal"
                                                >
                                                    Original
                                                </Link>
                                                <Link
                                                    to={getLink(
                                                        `/imprimir/facturas/${factura.id}`
                                                    )}
                                                    target="_blank"
                                                    className="dropdown-item"
                                                    title="Impresión normal"
                                                >
                                                    Impresión
                                                </Link>
												{/* {canPrint(factura) ? (
													<button
														className="dropdown-item d-none d-sm-inline"
														data-toggle="tooltip"
														data-placement="top"
														title="Impresión Doble"
														data-original-title="Impresión Doble"
														onClick={() =>
															openInfoPagoModal(
																factura,
																"doble"
															)
														}
													>
														Impresión doble
													</button>
												) : (
												)} */}
                                                <Link
                                                    to={getLink(
                                                        `/imprimir/facturas/${factura.id}/doble`
                                                    )}
                                                    target="_blank"
                                                    className="dropdown-item d-none d-sm-inline"
                                                    title="Impresión Doble"
                                                >
                                                    Impresión doble
                                                </Link>
												{factura.facturaFirmada?.length > 0 && <a
                                                                        href={factura.facturaFirmada[0].url}
                                                                        className="dropdown-item"
                                                                        target="_blank" rel="noreferrer"
                                                                    >
                                                                        Factura Firmada
                                                                    </a>}
												{(isVendedor() &&
													isPastDate(factura.fecha) &&
													factura.estado ===
														"pendiente") ||
												(!isVendedor() &&
													factura.estado ===
														"pagada") ? (
													<button
														className="dropdown-item"
														onClick={() =>
															openPINModal(
																factura
															)
														}
													>
														Editar
													</button>
												) : (
													factura.estado ===
														"pendiente" && (
														<Link
															to={getLink(
																`/ingresos/facturas/${factura.id}/edit`
															)}
															className="dropdown-item"
															title="Editar"
														>
															Editar
														</Link>
													)
												)}
											</>
										)}
									</Dropdown>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

const FacturasListMobile = ({
	facturas,
	openValidarFacturaModal,
	openPagarFacturaModal,
	openInfoPagoModal,
	openPINModal,
}) => {
	return (
		<div
			className="table-row__mobile w-100"
			style={{ overflowX: "visible" }}
		>
			{facturas.data.map((factura) => (
				<div className="col-12" key={factura.id}>
					<fieldset
						className="fieldset px-2"
						style={{ border: "2px solid #008aab" }}
					>
						<legend>{factura.numero_comprobante}</legend>
						<div className="row">
							<div className="form-group col-12 mb-1">
								<label className="orden-filter-label d-flex justify-content-between">
									<span>Cliente</span>
									<span
										className={`text-success pr-2 font-weight-normal ${
											factura.estado === "pendiente" &&
											factura.recurrencia_id &&
											factura.validada === "si"
												? "d-block"
												: "d-none"
										}`}
										style={{ fontSize: "0.9rem" }}
									>
										Validada
									</span>
								</label>
								<input
									type="text"
									placeholder="Cliente"
									value={factura.cliente?.nombre}
									className="form-control"
									style={{ backgroundColor: "#fff" }}
									readOnly
								/>
							</div>
						</div>
						<div className="row">
                            <div className="form-group col-6 pr-1 mb-1">
								<label className="orden-filter-label">
									Fecha emisión
								</label>
								<input
									type="text"
									className="form-control"
									style={{ backgroundColor: "#fff" }}
									value={dayjs(factura.fecha).format(
										"DD/MM/YYYY"
									)}
									readOnly
								/>
							</div>
							<div className="form-group col-5 pl-1 mb-1">
								<label className="orden-filter-label">
									Estado
								</label>
								<input
									type="text"
									placeholder="Estado"
									value={factura.estado}
									style={{ backgroundColor: "#fff" }}
									className="form-control text-capitalize"
									readOnly
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-6 pr-1 mb-1">
								<label className="orden-filter-label">
									Vencimiento
								</label>
								<input
									type="text"
									className="form-control"
									style={{ backgroundColor: "#fff" }}
									value={dayjs(
										factura.fecha_vencimiento
									).format("DD/MM/YYYY")}
									readOnly
								/>
							</div>
						</div>
						<div className="row">
							<div className="form-group col-7 pr-1 mb-1">
								<label className="orden-filter-label">
									Total
								</label>
								<input
									type="text"
									placeholder="Cliente"
									value={formatCurrency(factura.total, "$")}
									style={{ backgroundColor: "#fff" }}
									className="form-control"
									readOnly
								/>
							</div>
							<div className="form-group col-5 pl-1 mb-1">
								<label>
									<br />
								</label>
								<Dropdown
									classes={{ button: "w-100" }}
									styles={{
										button: {
											height: "38px",
											lineHeight: "0px",
											border: "1px solid #FE9D1F",
										},
										dropdownMenu: {
											zIndex: "99",
										},
									}}
								>
									{canValidate(factura) ? (
										<button
											className="dropdown-item"
											onClick={() =>
												openValidarFacturaModal(factura)
											}
										>
											Validar
										</button>
									) : (
										<>
											{canPay(factura) && (
												<button
													className="dropdown-item"
													onClick={() =>
														openPagarFacturaModal(
															factura
														)
													}
												>
													Pagar
												</button>
											)}
											{canPrint(factura) ? (
												<button
													type="button"
													className="dropdown-item"
													title="Impresión normal"
													onClick={() =>
														openInfoPagoModal(
															factura,
															"normal"
														)
													}
												>
													Impresión
												</button>
											) : (
												<Link
													to={getLink(
														`/imprimir/facturas/${factura.id}`
													)}
													target="_blank"
													className="dropdown-item"
													title="Impresión normal"
												>
													Impresión
												</Link>
											)}
											{canPrint(factura) ? (
												<button
													className="dropdown-item d-none d-sm-inline"
													data-toggle="tooltip"
													data-placement="top"
													title="Impresión Doble"
													data-original-title="Impresión Doble"
													onClick={() =>
														openInfoPagoModal(
															factura,
															"doble"
														)
													}
												>
													Impresión doble
												</button>
											) : (
												<Link
													to={getLink(
														`/imprimir/facturas/${factura.id}/doble`
													)}
													target="_blank"
													className="dropdown-item d-none d-sm-inline"
													title="Impresión Doble"
												>
													Impresión doble
												</Link>
											)}
											{isVendedor() &&
											isPastDate(factura.fecha) &&
											factura.estado === "pendiente" ? (
												<button
													className="dropdown-item"
													onClick={() =>
														openPINModal(factura)
													}
												>
													Editar
												</button>
											) : (
												factura.estado ===
													"pendiente" && (
													<Link
														to={getLink(
															`/ingresos/facturas/${factura.id}/edit`
														)}
														className="dropdown-item"
														title="Editar"
													>
														Editar
													</Link>
												)
											)}
										</>
									)}
								</Dropdown>
							</div>
						</div>
					</fieldset>
				</div>
			))}
		</div>
	);
};

export const TarjetaInfo = ({ factura }) => {
	const detalleMetodoPago =
		typeof factura.detalle_metodo_pago === "string"
			? JSON.parse(factura.detalle_metodo_pago)
			: factura.detalle_metodo_pago;

	return (
		<>
			<div className="form-group">
				<label>Terminal de tarjeta</label>
				<input
					type="text"
					name="detalle_metodo_pago[terminal_tarjeta]"
					defaultValue={detalleMetodoPago?.terminal_tarjeta}
					className="form-control"
				/>
			</div>
			<div className="form-group">
				<label>Tipo</label>
				<select
					className="form-control"
					name="detalle_metodo_pago[tipo]"
					defaultValue={detalleMetodoPago?.tipo}
				>
					<option value="visa">Visa</option>
					<option value="mastercard">Mastercard</option>
					<option value="otro">Otro</option>
				</select>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="detalle_metodo_pago[banco]"
					defaultValue={
						detalleMetodoPago?.hasOwnProperty("banco")
							? {
									label: detalleMetodoPago.banco,
									value: detalleMetodoPago.banco,
							  }
							: { label: "Seleccione", value: "" }
					}
				/>
			</div>
			<div className="form-group">
				<label>Numero de aprobación</label>
				<input
					type="text"
					className="form-control"
					name="detalle_metodo_pago[num_aprobacion]"
					defaultValue={detalleMetodoPago?.num_aprobacion}
				/>
			</div>
		</>
	);
};

export const ChequeInfo = ({ factura }) => {
	const detalleMetodoPago =
		typeof factura.detalle_metodo_pago === "string"
			? JSON.parse(factura.detalle_metodo_pago)
			: factura.detalle_metodo_pago;

	return (
		<>
			<div className="form-group">
				<label>Numero de cheque</label>
				<input
					type="text"
					name="detalle_metodo_pago[num_cheque]"
					className="form-control"
					defaultValue={detalleMetodoPago?.num_cheque}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="institucion_bancaria"
					defaultValue={{ label: "Seleccione", value: "" }}
				/>
			</div>
		</>
	);
};

export const TransferenciaInfo = ({ factura }) => {
	const detalleMetodoPago =
		typeof factura.detalle_metodo_pago === "string"
			? JSON.parse(factura.detalle_metodo_pago)
			: factura.detalle_metodo_pago;

	return (
		<>
			<div className="form-group">
				<label>Numero de Transferencia</label>
				<input
					type="text"
					name="detalle_metodo_pago[num_transferencia]"
					className="form-control"
					defaultValue={detalleMetodoPago?.num_transferencia}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="institucion_bancaria"
					defaultValue={{ label: "Seleccione", value: "" }}
				/>
			</div>
		</>
	);
};

export const OtroInfo = ({ factura }) => {
	const detalleMetodoPago =
		typeof factura.detalle_metodo_pago === "string"
			? JSON.parse(factura.detalle_metodo_pago)
			: factura.detalle_metodo_pago;

	return (
		<>
			<div className="form-group">
				<label>Numero de transaccion</label>
				<input
					type="text"
					name="detalle_metodo_pago[num_transaccion]"
					className="form-control"
					defaultValue={detalleMetodoPago?.num_transaccion}
				/>
			</div>
		</>
	);
};

export const DepositoInfo = ({ factura }) => {
	const detalleMetodoPago =
		typeof factura.detalle_metodo_pago === "string"
			? JSON.parse(factura.detalle_metodo_pago)
			: factura.detalle_metodo_pago;

	return (
		<>
			<div className="form-group">
				<label>Numero de Deposito</label>
				<input
					type="text"
					name="detalle_metodo_pago[num_deposito]"
					className="form-control"
					defaultValue={detalleMetodoPago?.num_deposito}
				/>
			</div>
			<div className="form-group">
				<label className="label form-control-label">
					Institución Financiera:
				</label>
				<Select
					isClearable
					options={bancos}
					name="institucion_bancaria"
					defaultValue={{ label: "Seleccione", value: "" }}
				/>
			</div>
		</>
	);
};

export const PagoModalFooter = React.memo(
	({
		setMostrar,
		setTipoImpresionAfterPago,
		formRef = null,
		guardarYSalir,
		saving,
	}) => {
		const submitForm = async (type) => {
			await setTipoImpresionAfterPago(type);
			if (formRef) {
				formRef.current.dispatchEvent(
					new Event("submit", { bubbles: true, cancelable: true })
				);
				return;
			}
			guardarYSalir("pagar");
		};
		return (
			<>
				<button
					type="button"
					onClick={() => {
						setMostrar(false);
					}}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{saving ? (
					<Loader type="small" />
				) : (
					<>
						<button
							onClick={() => submitForm("doble")}
							type="button"
							className="btn btn-outline-primary mr-2"
						>
							Imprimir doble
						</button>
						<button
							onClick={() => submitForm("normal")}
							type="button"
							className="btn btn-primary"
						>
							Imprimir
						</button>
					</>
				)}
			</>
		);
	}
);

export default Facturas;
